import React from "react";
import { Input, AddressInput } from "@components";
import { DeleteOutlined, HomeOutlined } from "@ant-design/icons";
import _ from "lodash";
import "./style.scss";
import { Button, Checkbox } from "antd";

export default function BlockLocation({ data, onChange, disabled }) {
  function handleChangeLocation(index, key, value) {
    const newFormVal = _.cloneDeep(data);
    newFormVal[index][key] = value;
    onChange((prev) => newFormVal);
  }

  function handleChangeLocationAddress(index, address) {
    const newFormVal = _.cloneDeep(data);
    newFormVal[index]["address"] = address.address;
    newFormVal[index]["city"] = address.city;
    newFormVal[index]["state"] = address.state;
    newFormVal[index]["zip"] = address.zip;

    onChange((prev) => newFormVal);
  }

  function handleAddNewLoc(index) {
    const newFormVal = _.cloneDeep(data);
    newFormVal.push({
      address: "",
      city: "",
      state: "",
      zip: ""
    });
    onChange((prev) => newFormVal);
  }

  function handleDeleteLoc(index) {
    if (data.length === 0) return;
    const newFormVal = _.cloneDeep(data);
    newFormVal.splice(index, 1);
    onChange((prev) => newFormVal);
  }

  function renderLocInfo(index, locationItem) {
    return (
      <div className="block_loc-item" key={`loc-item-${index}`}>
        <div className="block_loc-item-wrapper">
          <div className="form-group-three-container">
            <AddressInput
              title="Location"
              type="text"
              value={locationItem.address}
              onChange={(value) =>
                handleChangeLocation(index, "address", value)
              }
              onSelect={(address) =>
                handleChangeLocationAddress(index, address)
              }
            />
            <div>
              <Input
                title="City"
                type="text"
                value={locationItem.city}
                required
                onChange={(evt) =>
                  handleChangeLocation(index, "city", evt.target.value)
                }
              />
            </div>
            <div className="form-group-two-container">
              <Input
                title="State"
                type="text"
                value={locationItem.state}
                required
                onChange={(evt) =>
                  handleChangeLocation(index, "state", evt.target.value)
                }
              />
              <Input
                title="Zip"
                type="text"
                value={locationItem.zip}
                required
                onChange={(evt) =>
                  handleChangeLocation(index, "zip", evt.target.value)
                }
              />
            </div>
          </div>
          <div className="block_loc-item-wrapper-option">
            <Checkbox
              checked={locationItem.isMailing}
              onChange={(evt) =>
                handleChangeLocation(index, "isMailing", evt.target.checked)
              }
            >
              Mailing
            </Checkbox>
            <Checkbox
              checked={locationItem.isBilling}
              onChange={(evt) =>
                handleChangeLocation(index, "isBilling", evt.target.checked)
              }
            >
              Billing
            </Checkbox>
            <Checkbox
              checked={locationItem.isPhysical}
              onChange={(evt) =>
                handleChangeLocation(index, "isPhysical", evt.target.checked)
              }
            >
              Physical
            </Checkbox>
          </div>
        </div>
        <div className="block_loc-item-action">
          {!disabled && (
            <Button
              className="block_loc-item-action-btn"
              onClick={() => handleDeleteLoc(index)}
            >
              <DeleteOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="block_loc">
      {data.map((locItem, index) => renderLocInfo(index, locItem))}
      {!disabled && (
        <div className="block_loc-footer">
          <Button className="block_loc-footer-link" onClick={handleAddNewLoc}>
            <HomeOutlined />
            Add Address
          </Button>
        </div>
      )}
    </div>
  );
}

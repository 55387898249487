import InputAddress from "@components/input/address";
import InputMask from "@components/input/mask";
import InputShowHide from "@components/input/show-hide";
import InputText from "@components/input/text";
import { requiredZodErr } from "@utils/common";
import InputSelect from "@components/input/select";
import { ENTITY_CODE } from "@assets/const/legal-entity";
import { z } from "zod";

export default function ProfileFormVal({
  rhfKey,
  form: {
    register,
    control,
    formState: { errors },
    setValue
  },
  disabled
}) {
  return (
    <div>
      <div className="form-group-three-container">
        <InputText
          title="First Name"
          disabled={disabled}
          rhfKey={`${rhfKey}.first_name`}
          register={register}
          error={errors[rhfKey]?.first_name?.message}
        />
        <InputText
          title="Last Name"
          disabled={disabled}
          rhfKey={`${rhfKey}.last_name`}
          register={register}
          error={errors[rhfKey]?.last_name?.message}
        />
        <InputMask
          title="Telephone"
          type="text"
          mask="(999) 999-9999"
          disabled={disabled}
          rhfKey={`${rhfKey}.telephone`}
          register={register}
          error={errors[rhfKey]?.telephone?.message}
        />
      </div>
      <div className="form-group-three-container">
        <InputText
          title="Entity Name"
          disabled={disabled}
          rhfKey={`${rhfKey}.entity_name`}
          register={register}
          error={errors[rhfKey]?.entity_name?.message}
        />
        <InputSelect
          title="Entity Type"
          control={control}
          options={ENTITY_CODE}
          rhfKey={`${rhfKey}.entity_type`}
          error={errors[rhfKey]?.entity_type?.message}
        />
        <InputText
          title="DBA"
          disabled={disabled}
          rhfKey={`${rhfKey}.dba`}
          register={register}
          error={errors[rhfKey]?.dba?.message}
        />
      </div>
      <div className="form-group-three-container">
        <InputText
          title="Center Name"
          disabled={disabled}
          rhfKey={`${rhfKey}.centerName`}
          register={register}
          error={errors[rhfKey]?.centerName?.message}
        />
        <InputText
          title="Estimated Annual Revenue"
          disabled={disabled}
          rhfKey={`${rhfKey}.annualRevenue`}
          register={register}
          error={errors[rhfKey]?.annualRevenue?.message}
        />
        <InputShowHide
          title="Tax ID"
          error={errors[rhfKey]?.tax_id?.message}
          renderInput={(hidden) => (
            <InputMask
              type={hidden ? "password" : "text"}
              maskChar={hidden ? "" : "_"}
              mask="99-9999999"
              disabled={disabled}
              rhfKey={`${rhfKey}.tax_id`}
              register={register}
              unwrapped={true}
            />
          )}
        />
      </div>
      <InputAddress
        title="Mailing/Billing Address"
        control={control}
        rhfKey={`${rhfKey}.address`}
        disabled={disabled}
        onPlaceSelected={({ zip, city, state, address }) => {
          setValue(`${rhfKey}.address`, address, {
            shouldValidate: true
          });
          setValue(`${rhfKey}.zip`, zip, { shouldValidate: true });
          setValue(`${rhfKey}.city`, city, { shouldValidate: true });
          setValue(`${rhfKey}.state`, state, { shouldValidate: true });
        }}
        error={errors[rhfKey]?.address?.message}
      />
      <div className="form-group-three-container">
        <InputText
          title="City"
          disabled={disabled}
          rhfKey={`${rhfKey}.city`}
          register={register}
          error={errors[rhfKey]?.city?.message}
        />
        <InputText
          title="State"
          disabled={disabled}
          rhfKey={`${rhfKey}.state`}
          register={register}
          error={errors[rhfKey]?.state?.message}
        />
        <InputMask
          title="Zip"
          mask="99999"
          disabled={disabled}
          rhfKey={`${rhfKey}.zip`}
          register={register}
          error={errors[rhfKey]?.zip?.message}
        />
      </div>
    </div>
  );
}

export const defaultSchema = z.object({
  first_name: z
    .string(requiredZodErr("First name"))
    .nonempty(requiredZodErr("First name")),
  last_name: z
    .string(requiredZodErr("Last name"))
    .nonempty(requiredZodErr("Last name")),
  entity_name: z
    .string(requiredZodErr("Entity name"))
    .nonempty(requiredZodErr("Entity name")),
  entity_type: z
    .string(requiredZodErr("Entity type"))
    .nonempty(requiredZodErr("Entity type")),
  dba: z.string(requiredZodErr("DBA")).nonempty(requiredZodErr("DBA")),
  email: z
    .string(requiredZodErr("Email"))
    .nonempty(requiredZodErr("Email"))
    .email(),
  telephone: z
    .string(requiredZodErr("Telephone"))
    .nonempty(requiredZodErr("Telephone"))
    .regex(
      /^(?:\+?\d{1,3}[-.\s]?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
      "The Telephone field must follow the format (999) 999-9999"
    ),
  address: z
    .string(requiredZodErr("Address"))
    .nonempty(requiredZodErr("Address")),
  state: z.string(requiredZodErr("State")).nonempty(requiredZodErr("State")),
  zip: z.string(requiredZodErr("Zip")).nonempty(requiredZodErr("Zip")),
  city: z.string(requiredZodErr("City")).nonempty(requiredZodErr("City")),
  tax_id: z
    .string(requiredZodErr("Tax ID"))
    .nonempty(requiredZodErr("Tax ID"))
    .regex(
      /^\d{2}-\d{7}$/,
      "The Tax ID field must follow the format 12-3456789"
    )
});

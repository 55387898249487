import React, { useState } from "react";
import { Input, Loading } from "../../../../../components";
import { Button, Modal, notification, Table, Switch } from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import "./style.scss";
import { post } from "../../../../../utils/axios";
import { useAppState } from "../../../../../context";
import { useAuth0 } from "@auth0/auth0-react";
import { STATUS_PENDING } from "../../../../../assets/const/status";
import dayjs from "dayjs";
import { filterValue } from "@utils/common";

export default function IntegrationServiceminder(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [expand, setExpand] = useState(false);
  const [loading, setLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const { franchisorAdmin, setFranchisorAdmin } = useAppState();
  const [franchiseeListData, setFranchiseeListData] = useState([]);
  const [rawFranchiseeListData, setRawFranchiseeListData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function onSelectChange(selectedRowKeysUpdate) {
    setSelectedRowKeys(selectedRowKeysUpdate);
  }

  function handleRemove() {
    const { data, dataIndex, setData } = props;
    data.splice(dataIndex, 1);
    setData([...data]);
  }

  async function handleTest() {
    const { data, dataIndex } = props;
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const param = {
        apiKey: data[dataIndex].apiKey,
      };
      if (franchisorAdmin?._id) {
        const result = await post(
          `serviceminder/auth`,
          { ...param },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          if ((result.data?.data || []).length > 0) {
            notification["success"]({
              message: "Integration Success",
              description: "Integration is tested successfully",
              style: {
                background: "#f6ffed",
                border: "1px solid #b7eb8f",
              },
            });
          } else {
            notification["error"]({
              message: "Integration Test Fail",
              description: "Integration Test is failed",
              style: {
                background: "#fef2f0",
                border: "1px solid #fbccc7",
              },
            });
          }
        }
      }
    } catch (error) {
      notification["error"]({
        message: "Integration Test Fail",
        description: "Integration Test is failed",
        style: {
          background: "#fef2f0",
          border: "1px solid #fbccc7",
        },
      });
    }
    setLoading(false);
  }

  async function handleIntegate() {
    const { data, dataIndex, setData } = props;
    setLoading(true);

    try {
      const token = await getAccessTokenSilently();
      let result = await post(
        `serviceminder/auth`,
        {
          apiKey: data[dataIndex].apiKey,
          requirementId: franchisorAdmin._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result.data.success) {
        if ((result.data?.data || []).length > 0) {
          setRawFranchiseeListData(result.data?.data);
          setFranchiseeListData(
            result.data?.data.map((item) => {
              return {
                franchisee_name: item.Name,
                key: item.OrganizationId,
                franchisee_email: item.Email,
              };
            })
          );
          const resultSelectKeys = result.data?.data.map(
            (item) => item.OrganizationId
          );
          setSelectedRowKeys(resultSelectKeys);
          setIsOpen(true);

          console.log("result data orgniazation", result.data?.data);
        } else {
          notification["error"]({
            message: "Failed",
            description:
              "Failed to fetch Franchisee. Please contact admin directly!",
            duration: 3,
            style: {
              background: "#fef2f0",
              border: "1px solid #fbccc7",
            },
          });
        }
      } else {
        notification["error"]({
          message: "Failed",
          description:
            "Failed to fetch Franchisee. Please contact admin directly!",
          duration: 3,
          style: {
            background: "#fef2f0",
            border: "1px solid #fbccc7",
          },
        });
      }
      // if (result.status === 200) {
      //   let { access_token } = result.data.data;
      //   let resultFranchiseeList = await post(
      //     `franconnect/franchisee-list`,
      //     {
      //       token: access_token,
      //       tenantID: data[dataIndex].tenantID,
      //     },
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //       },
      //     }
      //   );

      //   if (resultFranchiseeList.status === 200) {
      //     const resultData = JSON.parse(
      //       JSON.stringify(Object.values(resultFranchiseeList.data.data))
      //     );
      //     if (resultData && resultData.length > 0) {
      //       for (let index = 0; index < resultData.length; index++) {
      //         resultData[index].key = resultData[index].franchisee_no;
      //       }
      //     }
      //     const resultSelectKeys = resultData.map((item) => item.key);
      //     setFranchiseeListData(resultData);
      //     setSelectedRowKeys(resultSelectKeys);
      //     setIsOpen(true);
      //     setAccessToken(access_token);
      //   } else {
      //     notification["error"]({
      //       message: "Failed",
      //       description:
      //         "Failed to fetch Franchisee. Please contact admin directly!",
      //       duration: 3,
      //       style: {
      //         background: "#fef2f0",
      //         border: "1px solid #fbccc7",
      //       },
      //     });
      //   }
      // } else {
      //   notification["error"]({
      //     message: "Failed",
      //     description: "Invalid Credential",
      //     duration: 3,
      //     style: {
      //       background: "#fef2f0",
      //       border: "1px solid #fbccc7",
      //     },
      //   });
      // }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification["error"]({
        message: "Failed",
        description: "Invalid Credential",
        duration: 3,
        style: {
          background: "#fef2f0",
          border: "1px solid #fbccc7",
        },
      });
    }
  }

  function handleChange(key, value) {
    const { data, dataIndex, setData } = props;
    data[dataIndex][key] = value;
    setData([...data]);
  }

  async function handleImportFranchisee() {
    function parseItemOpenSince(item) {
      if (!item.OpenSince) return "";
      try {
        const openDateMatch = item.OpenSince?.match(/\d+/); // the OpenSince field comes in the format "/Date(unixEpochMilli)/""
        if (openDateMatch.length > 0) {
          return dayjs(parseInt(openDateMatch[0]), "YYYY-MM-DD").format();
        }
        return "";
      } catch (err) {
        console.log(
          `failed to parse item.OpenSince field from serviceminder: ${item.OpenSince}: ${err.message}`
        );
        return "";
      }
    }

    function determineFirstAndLastName(item) {
      const defaultFirstAndLastName = { first_name: item.Name, last_name: "" };
      const email = item.Email;
      if (!email || !item.Users || item.Users.length === 0)
        return defaultFirstAndLastName;

      const foundUser = item.Users.find((user) => user.Email === item.Email);
      if (!foundUser) return defaultFirstAndLastName;

      const [first_name, last_name] = foundUser.Name.split(" ");
      return {
        first_name: first_name || item.Name,
        last_name: last_name || "",
      };
    }

    const resultData = rawFranchiseeListData.filter((item) => {
      const selectedKey = selectedRowKeys.find(
        (key) => item.OrganizationId === key
      );
      if (selectedKey) {
        return true;
      } else {
        return false;
      }
    });

    const contactList = (resultData || [])
      .filter((item) => filterValue(item.Email))
      .map((item) => {
        return {
          first_name: determineFirstAndLastName(item).first_name,
          last_name: determineFirstAndLastName(item).last_name,
          dba: item.Name,
          entity_name: item.LegalEntityName || item.InternalName,
          address: item.StartAddress?.Address1 || item.StartAddress?.Address2,
          city: item.StartAddress?.City,
          state: item.StartAddress?.State,
          zip: item.StartAddress?.Zip,
          email: item.Email.toLowerCase(),
          telephone: item.PrimaryPhone,
          notes: "",
          requirementId: franchisorAdmin._id,
          activities: [],
          status: STATUS_PENDING,
          sourcePlatform: "serviceminder",
          tenantId: "",
          detail: item,
          tax_id: item.TaxpayerID,
          opening_date: parseItemOpenSince(item),
        };
      });
    console.log("importing franchisees: ", contactList);
    try {
      setImportLoading(true);
      const token = await getAccessTokenSilently();
      const franchiseeNo = selectedRowKeys.join(",");
      let resultFranchiseeDetail = await post(
        `contact/create-list`,
        {
          contacts: contactList,
          requirementId: franchisorAdmin._id,
          method: "overwrite",
          sourcePlatform: "serviceminder",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("result franchisee list ", resultFranchiseeDetail);
      setImportLoading(false);
      setIsOpen(false);
    } catch (e) {
      setImportLoading(false);
    }
  }
  function handleSelectCancel() {
    setSelectedRowKeys([]);
  }
  const { data, dataIndex } = props;

  const columns = [
    {
      title: "Franchisee Name",
      dataIndex: "franchisee_name",
    },
    {
      title: "Email",
      dataIndex: "franchisee_email",
    },
  ];

  return (
    <div className="admin_franchisee_integration_detail">
      {expand && (
        <div className="admin_franchisee_integration_detail-form">
          <div
            className="admin_franchisee_integration_detail-form-header"
            onClick={() => setExpand(false)}
          >
            <div className="admin_franchisee_integration_detail-form-header-title">
              {data[dataIndex].platform}
            </div>
            <DownOutlined />
          </div>
          <div className="admin_franchisee_integration_detail-form-main">
            <div className="admin_franchisee_integration_detail-form-main-wrapper">
              <div className="admin_franchisee_integration_detail-form-main-wrapper-left">
                <Input
                  title="API Key"
                  type="text"
                  placeholder="Please enter API Key"
                  description="Ex: XXXX"
                  value={data[dataIndex].apiKey}
                  onChange={(evt) => handleChange("apiKey", evt.target.value)}
                />
                <div>
                  <p className="input-title">Disable Auto Sync</p>
                  <Switch
                    checked={data[dataIndex].autoSyncDisable}
                    onChange={(evt) => handleChange("autoSyncDisable", evt)}
                  ></Switch>
                </div>
              </div>
              <div className="admin_franchisee_integration_detail-form-main-wrapper-right"></div>
            </div>
            <div className="admin_franchisee_integration_detail-form-main-footer">
              <Button onClick={handleTest}>Test</Button>
              <Button onClick={handleIntegate}>Import Franchisees</Button>
              <Button danger onClick={handleRemove}>
                Remove
              </Button>
            </div>
          </div>
        </div>
      )}
      {!expand && (
        <div
          className="admin_franchisee_integration_detail-preview"
          onClick={() => setExpand(true)}
        >
          <div className="admin_franchisee_integration_detail-preview-title">
            {data[dataIndex].platform}
          </div>
          <RightOutlined />
        </div>
      )}

      <Loading loading={loading} />
      <Modal
        title="Import Franchisees"
        open={isOpen}
        onOk={handleImportFranchisee}
        onCancel={() => setIsOpen(false)}
        className="admin_franchisee_integration_detail-modal"
        okText="Import"
      >
        <div className="admin_franchisee_integration_detail-modal-wrapper">
          <div className="admin_franchisee_integration_detail-modal-wrapper_header">
            {selectedRowKeys.length} items selected
            <a className="btn-cancel" onClick={handleSelectCancel}>
              Cancel
            </a>
          </div>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={franchiseeListData}
          />
          <Loading loading={importLoading} />
        </div>
      </Modal>
    </div>
  );
}

import React from "react";
import { Controller } from "react-hook-form";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import InputWrapper from "../wrapper";
import { extractLocationData } from "@utils/google-map";

const InputAddress = React.forwardRef(
  (
    {
      rhfKey,
      control,
      onPlaceSelected,
      error,
      disabled,
      disableValidation,
      ...props
    },
    ref
  ) => {
    const input = ({ field }) => (
      <InputWrapper {...props} error={error}>
        <ReactGoogleAutocomplete
          className="custom-input ant-input ant-input-lg custom-antd-input"
          onPlaceSelected={(place) => {
            onPlaceSelected(extractLocationData(place.address_components));
          }}
          options={{
            types: ["address"],
          }}
          apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
          {...field}
          disabled={disabled}
        />
      </InputWrapper>
    );

    if (control) {
      return (
        <Controller
          name={rhfKey}
          control={control}
          rules={disableValidation ? { validate: () => true } : undefined}
          render={({ field }) => input({ field })}
        />
      );
    }
    return input({});
  }
);

export default InputAddress;

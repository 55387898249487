import DriverForm from "@components/forms/driver";
import VehicleForm from "@components/forms/vehicle";
import { Radio } from "antd";

export default function VehicleDriverSection({ form, show, onShowToggle }) {
  return (
    <div className="show-form-toggle-container">
      <h4>Do you have vehicles and drivers you'd like to add?</h4>
      <Radio.Group value={show || false} onChange={onShowToggle}>
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group>
      {show && (
        <div className="form-container">
          <VehicleForm
            form={form}
            disableValidation={!show}
            rhfKey="vehicleFormVal"
          />
          <DriverForm
            form={form}
            disableValidation={!show}
            rhfKey="driverFormVal"
          />
        </div>
      )}
    </div>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import "./style.scss";
import ImageUploadFolder from "../../../assets/images/upload-folder.png";
import { Button, Progress, Breadcrumb, Tabs, Select, DatePicker } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useAppState } from "@context/index";
import {
  Card,
  Input,
  Loading,
  OriginalRecordForm,
  ButtonGroup,
} from "@components";
import { useAuth0 } from "@auth0/auth0-react";
import { get, post, put } from "@utils/axios";
import {
  DO_NOT_CONTACT_OPTION_CANADA_BUSINESS,
  DO_NOT_CONTACT_OPTION_INACTIVE_ZEE,
  DO_NOT_CONTACT_OPTION_IS_LEAD,
  DO_NOT_CONTACT_OPTION_NONE,
  DO_NOT_CONTACT_OPTION_NOT_COVERAGE,
  DO_NOT_CONTACT_OPTION_NOT_OPEN,
  DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER,
  DO_NOT_CONTACT_OPTION_CANCEL_POLICY,
  REQUIREMENT_STATUS_DASHBOARD,
} from "@assets/const/status";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import InsuredInfor from "../../admin-board/franchisee-detail/insured-infor";
import ConsultantInfor from "../../admin-board/franchisee-detail/consultant-infor";
import PolicyDetails from "../../admin-board/franchisee-detail/policy-details";
import POCContact from "../../admin-board/franchisee-detail/poc-contact";
import UnderWriting from "../../admin-board/franchisee-detail/under-writing";
import ComplianceList from "../../admin-board/franchisee-detail/compliance-list";
import DocumentList from "../../admin-board/franchisee-detail/document-list";
import ActivityList from "../../admin-board/franchisee-detail/activity-list";
import PortalLink from "../../admin-board/franchisee-detail/portal-link";
import InsuranceUpload from "../../admin-board/franchisee-detail/insurance-upload";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ScreenMask from "@components/screen-mask";
import { VIEW_SOURCE_ZEE } from "@assets/const/ui";
import { APP_DATE_FORMAT } from "@assets/const/form";

const { TabPane } = Tabs;

const TAB_ITEM_ACCOUNT = "TAB_ITEM_ACCOUNT";
const TAB_ITEM_INTEGRATION = "TAB_ITEM_INTEGRATION";
const TAB_ITEM_UNDERWRITING = "TAB_ITEM_UNDERWRITING";
const TAB_ITEM_COMPLIANCE = "TAB_ITEM_COMPLIANCE";

const DO_NOT_CONTACT_OPTION = [
  {
    value: DO_NOT_CONTACT_OPTION_RIKOR_CUSTOMER,
    label: "Rikor Customer",
  },
  {
    value: DO_NOT_CONTACT_OPTION_INACTIVE_ZEE,
    label: "Inactive Zee",
  },
  {
    value: DO_NOT_CONTACT_OPTION_IS_LEAD,
    label: "New Lead",
  },
  {
    value: DO_NOT_CONTACT_OPTION_NOT_OPEN,
    label: "Not Yet Open for Business",
  },
  {
    value: DO_NOT_CONTACT_OPTION_NOT_COVERAGE,
    label: "Rikor Cannot Obtain Coverage",
  },
  {
    value: DO_NOT_CONTACT_OPTION_CANADA_BUSINESS,
    label: "Canadian Business",
  },
  {
    value: DO_NOT_CONTACT_OPTION_CANCEL_POLICY,
    label: "Cancel Policy",
  },
  {
    value: DO_NOT_CONTACT_OPTION_NONE,
    label: "Non-Rikor Customer",
  },
];

export default function FranchiseeOverview(props) {
  const { authSetting, franchisorAdmin } = useAppState();
  const [franchiseeData, setFranchiseeData] = useState({});
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [visibleOri, setVisibleOri] = useState(false);
  const [consultantData, setConsultantData] = useState(null);
  const [dataSourceActivity, setDataSourceActivity] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (authSetting?.user?.contactId) {
      handleLoadFranchisee();
    }
  }, [authSetting]);

  async function handleLoadFranchisee() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${authSetting?.user?.contactId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFranchiseeData(result.data.data || {});
      setDataSourceActivity((result.data.data.activities || []).reverse());
      const resultRequirement = await get(
        `requirement/${result.data.data.requirementId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // setFranchisorData(resultRequirement.data.data);
      if (resultRequirement?.data?.data?.consultantId) {
        const resultConsultant = await get(
          `user/${resultRequirement?.data?.data?.consultantId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setConsultantData(resultConsultant?.data?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  }

  function handleViewReport() {
    history.push(`/board/report`);
  }
  function handleNavigateBack() {}
  async function handleSave(key, value) {
    setLoading(true);
    try {
      const param = {
        [key]: value,
      };
      const token = await getAccessTokenSilently();
      const result = await put(`contact/${franchiseeData._id}`, param, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setFranchiseeData(result.data.entity);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const franchiseeName = useMemo(() => {
    if (franchiseeData) {
      if (franchiseeData?.isInsureDiff) {
        return `${franchiseeData?.insuranceData?.first_name || "Franchisee"} ${
          franchiseeData?.insuranceData?.last_name || "Name"
        }`;
      }

      return `${franchiseeData.first_name || "Franchisee"} ${
        franchiseeData.last_name || "Name"
      }`;
    }

    return "Franchisee Name";
  }, [franchiseeData]);

  const operations = (
    <div className="toolbar_wrapper">
      <div className="toolbar_wrapper-ele">
        <div className="toolbar_wrapper-ele-label">Record Status:</div>
        <Select
          style={{ minWidth: "150px" }}
          placeholder="Record Status"
          onChange={(val) => handleSave("doNotContact", val)}
          options={DO_NOT_CONTACT_OPTION}
          value={franchiseeData?.doNotContact}
          size="large"
        />
      </div>
      <div className="toolbar_wrapper-ele">
        <div className="toolbar_wrapper-ele-label">Timer:</div>
        <DatePicker
          format={APP_DATE_FORMAT}
          value={
            !isEmpty(franchiseeData?.timePeriod)
              ? dayjs(franchiseeData?.timePeriod)
              : null
          }
          onChange={(val) => {
            if (val) {
              handleSave("timePeriod", val.toISOString());
            } else {
              handleSave("timePeriod", null);
            }
          }}
          size="large"
        />
      </div>
      <ScreenMask />
    </div>
  );

  return (
    <div className="zee_overview">
      {franchisorAdmin?.personal_infor_logo && (
        <div className="zee_overview-logo">
          <img
            className="zee_overview-logo-img"
            src={franchisorAdmin?.personal_infor_logo}
          />
        </div>
      )}

      <div className="zee_overview-header">
        <Breadcrumb>
          <Breadcrumb.Item onClick={handleNavigateBack}>
            {franchisorAdmin?.personal_infor_business_name || "Franchisor"}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{franchiseeName}</Breadcrumb.Item>
        </Breadcrumb>

        <div className="zee_overview-header-action">
          <ButtonGroup
            buttonList={[
              {
                label: "Report",
                onClick: handleViewReport,
              },
            ]}
          />
        </div>
      </div>
      <div className="zee_overview-container">
        <Tabs
        type="card"
          defaultActiveKey={TAB_ITEM_ACCOUNT}
          tabBarExtraContent={operations}
        >
          <TabPane tab="Account" key={TAB_ITEM_ACCOUNT}>
            <div className="zee_overview-container-acc">
              <div>
                <InsuredInfor franchiseeData={franchiseeData} />
                <Card
                  title="Original Record"
                  extra={() => (
                    <div
                      className="zee_overview-container-acc-action"
                      onClick={() => setVisibleOri(!visibleOri)}
                      type="primary"
                    >
                      {!visibleOri ? (
                        <CaretDownOutlined />
                      ) : (
                        <CaretUpOutlined />
                      )}
                    </div>
                  )}
                >
                  {visibleOri && (
                    <>
                      <OriginalRecordForm
                        disabled={true}
                        data={franchiseeData}
                      />

                      <div className="form-group-container">
                        <div className="line-item-container">
                          <Input
                            title="RIKOR ID"
                            type="text"
                            value={franchiseeData._id}
                            disabled
                          />
                        </div>
                      </div>
                    </>
                  )}
                </Card>

                <ConsultantInfor consultantData={consultantData} />
                {/* <PolicyDetails franchiseeData={franchiseeData} /> */}
              </div>
              <div>
                <POCContact
                  franchiseeData={franchiseeData}
                  franchisorData={franchisorAdmin}
                />
              </div>
              <ScreenMask />
            </div>
          </TabPane>
          <TabPane tab="Underwriting" key={TAB_ITEM_UNDERWRITING}>
            <div className="zee_overview-container-underwriting">
              <UnderWriting
                franchiseeData={franchiseeData}
                franchisorData={franchisorAdmin}
              />
              <ScreenMask />
            </div>
          </TabPane>
          <TabPane tab="Compliance" key={TAB_ITEM_COMPLIANCE}>
            <div>
              <ComplianceList
                franchiseeData={franchiseeData}
                franchisorData={franchisorAdmin}
              />
              <DocumentList
                franchiseeData={franchiseeData}
                updateFranchiseeData={setFranchiseeData}
              />
              <ActivityList franchiseeData={franchiseeData} />
              <PortalLink franchiseeData={franchiseeData} />
              <InsuranceUpload
                zeeId={franchiseeData?._id}
                viewSource={VIEW_SOURCE_ZEE}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
      <Loading loading={loading} />
    </div>
  );
}

import _ from "lodash";
import "./style.scss";
import { DatePicker, Tooltip } from "antd";
import { CONSTRUCTION_TYPE_CODES } from "@assets/const/hartford";
import { BUILDING_SECURITY_PROTOCOLS } from "@assets/const/building";
import InputAddress from "@components/input/address";
import InputText from "@components/input/text";
import InputSelect from "@components/input/select";
import { z } from "zod";
import { requiredZodErr } from "@utils/common";
import InputDatePicker from "@components/input/date";
import InputMask from "@components/input/mask";

export default function BuildOutForm({
  disableValidation,
  rhfKey,
  form: {
    register,
    control,
    formState: { errors },
    setValue,
  },
}) {
  return (
    <div className="build-out-info-container">
      <div className="build-out-info-container-section">
        <div className="build-out-info-container-section-title">
          Project Address
        </div>
        <InputAddress
          title="Address"
          control={control}
          rhfKey={`${rhfKey}.address`}
          onPlaceSelected={({ zip, city, state, address }) => {
            setValue(`${rhfKey}.address`, address, {
              shouldValidate: true,
            });
            setValue(`${rhfKey}.zip`, zip, { shouldValidate: true });
            setValue(`${rhfKey}.city`, city, { shouldValidate: true });
            setValue(`${rhfKey}.state`, state, { shouldValidate: true });
          }}
          error={errors[rhfKey]?.address?.message}
          disableValidation={disableValidation}
        />
        <InputText
          title="Line 2"
          rhfKey={`${rhfKey}.line2`}
          register={register}
          disableValidation={disableValidation}
          error={errors[rhfKey]?.line2?.message}
        />
        <div className="form-group-three-container">
          <InputText
            title="City"
            rhfKey={`${rhfKey}.city`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.city?.message}
          />
          <InputText
            title="State"
            rhfKey={`${rhfKey}.state`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.state?.message}
          />
          <InputMask
            title="Zip"
            mask="99999"
            rhfKey={`${rhfKey}.zip`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.zip?.message}
          />
        </div>
      </div>

      <div className="build-out-info-container-section">
        <div className="build-out-info-container-section-title">
          Building Info
        </div>
        <div className="form-group-four-container">
          <Tooltip title="Original year the building was built where your project is">
            <div>
              <InputMask
                mask="9999"
                title="Year Built"
                rhfKey={`${rhfKey}.yearBuilt`}
                register={register}
                disableValidation={disableValidation}
                error={errors[rhfKey]?.yearBuilt?.message}
              />
            </div>
          </Tooltip>
          <Tooltip title="How many total square feet that the building will be occupying">
            <div>
              <InputText
                type="number"
                title="Square Feet"
                rhfKey={`${rhfKey}.squareFeet`}
                register={register}
                disableValidation={disableValidation}
                error={errors[rhfKey]?.squareFeet?.message}
              />
            </div>
          </Tooltip>
          <Tooltip title="How many stories does the building have">
            <div>
              <InputText
                type="number"
                title="# of Stories"
                rhfKey={`${rhfKey}.numOfStories`}
                register={register}
                disableValidation={disableValidation}
                error={errors[rhfKey]?.numOfStories?.message}
              />
            </div>
          </Tooltip>
          <InputText
            type="number"
            title="Total build-out cost"
            rhfKey={`${rhfKey}.buildOutCost`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.buildOutCost?.message}
          />
        </div>
      </div>

      <div className="build-out-info-container-section">
        <div className="build-out-info-container-section-title">
          Construction Info
        </div>
        <div className="form-group-four-container build-out-info-container-section-construction">
          <InputSelect
            title="Construction Type"
            control={control}
            options={CONSTRUCTION_TYPE_CODES}
            rhfKey={`${rhfKey}.constructionType`}
            error={errors[rhfKey]?.constructionType?.message}
            disableValidation={disableValidation}
          />
          <InputSelect
            title="Description of Security"
            control={control}
            options={BUILDING_SECURITY_PROTOCOLS}
            rhfKey={`${rhfKey}.descriptionOfSecurity`}
            error={errors[rhfKey]?.descriptionOfSecurity?.message}
            disableValidation={disableValidation}
          />
          <InputDatePicker
            title="Start Date"
            control={control}
            rhfKey={`${rhfKey}.constructionStartDate`}
            error={errors[rhfKey]?.constructionStartDate?.message}
            disableValidation={disableValidation}
          />
          <InputDatePicker
            title="Estimated End Date"
            control={control}
            rhfKey={`${rhfKey}.constructionEstimatedEndDate`}
            error={errors[rhfKey]?.constructionEstimatedEndDate?.message}
            disableValidation={disableValidation}
          />
        </div>
      </div>

      <div className="build-out-info-container-section">
        <div className="build-out-info-container-section-title">
          General Contractor
        </div>
        <div className="form-group-two-container">
          <InputText
            title="Business Name"
            rhfKey={`${rhfKey}.contractorBusinessName`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.contractorBusinessName?.message}
          />
          <InputAddress
            title="Mailing Address"
            control={control}
            rhfKey={`${rhfKey}.contractorMailingAddress`}
            onPlaceSelected={({ zip, city, state, address }) => {
              const fullAddress = [address, city, state, zip]
                .filter(Boolean)
                .join(", ");
              setValue(`${rhfKey}.contractorMailingAddress`, fullAddress, {
                shouldValidate: true,
              });
            }}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.contractorMailingAddress?.message}
          />
        </div>
      </div>

      <div className="build-out-info-container-section">
        <div className="build-out-info-container-section-title">Bank Info</div>
        <div className="form-group-two-container">
          <Tooltip title="Name of lender">
            <div>
              <InputText
                title="Bank Name"
                rhfKey={`${rhfKey}.bankName`}
                register={register}
                disableValidation={disableValidation}
                error={errors[rhfKey]?.bankName?.message}
              />
            </div>
          </Tooltip>
          <InputAddress
            title="Bank Mailing Address"
            control={control}
            rhfKey={`${rhfKey}.bankMailingAddress`}
            onPlaceSelected={({ zip, city, state, address }) => {
              const fullAddress = [address, city, state, zip]
                .filter(Boolean)
                .join(", ");
              setValue(`${rhfKey}.bankMailingAddress`, fullAddress, {
                shouldValidate: true,
              });
            }}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.bankMailingAddress?.message}
          />
        </div>
      </div>
    </div>
  );
}

export const defaultSchema = z.object({
  address: z
    .string(requiredZodErr("Address"))
    .nonempty(requiredZodErr("Address")),
  line2: z.string(),
  city: z.string(requiredZodErr("City")).nonempty(requiredZodErr("City")),
  state: z.string(requiredZodErr("State")).nonempty(requiredZodErr("State")),
  zip: z.string(requiredZodErr("Zip")).nonempty(requiredZodErr("Zip")),
  yearBuilt: z
    .string(requiredZodErr("Year built"))
    .nonempty(requiredZodErr("Year built")),
  squareFeet: z
    .string(requiredZodErr("Square feet"))
    .nonempty(requiredZodErr("Square feet")),
  numOfStories: z
    .string(requiredZodErr("Number of stories"))
    .nonempty(requiredZodErr("Number of stories")),
  buildOutCost: z
    .string(requiredZodErr("Build out cost"))
    .nonempty(requiredZodErr("Build out cost")),
  constructionType: z
    .string(requiredZodErr("Construction type"))
    .nonempty(requiredZodErr("Construction type")),
  descriptionOfSecurity: z
    .string(requiredZodErr("Description of security"))
    .nonempty(requiredZodErr("Description of security")),
  constructionStartDate: z
    .string(requiredZodErr("Construction start date"))
    .nonempty(requiredZodErr("Construction start date")),
  constructionEstimatedEndDate: z
    .string(requiredZodErr("Construction estimated end date"))
    .nonempty(requiredZodErr("Construction estimated end date")),
  contractorBusinessName: z
    .string(requiredZodErr("Contractor business name"))
    .nonempty(requiredZodErr("Contractor business name")),
  contractorMailingAddress: z
    .string(requiredZodErr("Contractor mailing address"))
    .nonempty(requiredZodErr("Contractor mailing address")),
  bankName: z
    .string(requiredZodErr("Bank name"))
    .nonempty(requiredZodErr("Bank name")),
  bankMailingAddress: z
    .string(requiredZodErr("Bank mailing address"))
    .nonempty(requiredZodErr("Bank mailing address")),
});

import React, { useState, useEffect, useMemo } from "react";
import "./style.scss";
import {
  Card,
  Input,
  Loading,
  QuoteAction,
  QuoteHeader,
  QuoteProgressBar,
  QuoteStepper,
} from "../../../components";
import { useAppState } from "../../../context";
import { AutoComplete, Checkbox } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { get, post } from "../../../utils/axios";
import { Form, Alert } from "antd";
import ClassCodeInput from "./class-code-input";
import { format, addMonths } from "date-fns";
import { GA_CONSTS } from "../../../assets/const/fim-integration";
import { getAbbrev } from "../../../assets/const/location";
import UnderWriting from "../franchisee-detail/under-writing";
import { maskInput, notify, validateSpecialInputFields } from "@utils/common";
import { TYPE_INPUT_TAX_ID } from "@assets/const/ui";

export default function AdminAppetite() {
  const history = useHistory();
  const location = useLocation();
  let { id } = useParams();
  const { codeList, setCodeList } = useAppState();
  const { user, getAccessTokenSilently } = useAuth0();
  const [franchiseeData, setFranchiseeData] = useState({});
  const [franchisorData, setFranchisorData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error] = useState(null);
  const [form] = Form.useForm();
  const [formVal, setFormVal] = useState({
    first_name: "",
    last_name: "",
    email: "",
    telephone: "",
    rikor_id: "",
    entity_name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    opening_date: "",
    tax_id: "",
  });
  const [nacisCode, setNacisCode] = useState({
    code: null,
    description: null,
  });
  useEffect(() => {
    handleLoadData();
  }, []);

  async function handleLoadData() {
    try {
      setLoading(true);
      if (id) {
        await handleLoadFranchisee();
      }

      if (!codeList) {
        await handleLoadCodes();
      }
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  }

  async function handleLoadCodes() {
    try {
      const token = await getAccessTokenSilently();
      const result = await get("admin/code", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const codeListData = result?.data?.data || [];
      console.log("code list fetched ---", codeListData);
      setCodeList(codeListData);
    } catch (error) {
      console.log("nacis code load error ---", error);
    }
  }

  async function handleLoadFranchisee() {
    try {
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const franchiseeData = result.data.data;
      setFranchiseeData(franchiseeData);
      if (franchiseeData?.isInsureDiff) {
        setFormVal({
          ...formVal,
          ...franchiseeData.insuranceData,
        });
      } else {
        setFormVal({
          ...formVal,
          first_name: franchiseeData?.first_name,
          last_name: franchiseeData?.last_name,
          email: franchiseeData?.email,
          telephone: franchiseeData?.telephone,
          rikor_id: franchiseeData?._id,
          entity_name: franchiseeData.entity_name,
          address: franchiseeData.address,
          city: franchiseeData.city,
          state: franchiseeData.state,
          zip: franchiseeData.zip,
          opening_date: franchiseeData?.detail?.openingDate,
          tax_id: franchiseeData?.detail?.fimEntity?.fimTtTaxpayer,
        });
      }

      const resultRequirement = await get(
        `requirement/${result.data.data.requirementId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFranchisorData(resultRequirement.data.data);
    } catch (error) {
      console.log("error", error);
    }
  }

  function handleBack() {
    history.push(`/admin/franchisees/${id}/insurance-quote`);
  }

  async function handleAppetite() {
    try {
      const classCode = franchiseeData.locationList[0].code;
      const token = await getAccessTokenSilently();
      const resultAppetite = await post(
        `gai/appetite`,
        {
          product: {
            product: "WC",
            criteria: {
              classCode: [classCode],
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("result appetite ---", resultAppetite);
    } catch (error) {
      console.log("error appetite ---", error);
    }
  }

  async function handleNext() {
    const err = validateSpecialInputFields(formVal);
    if (err) {
      notify("Form Invalid", err, "error");
      return;
    }

    form.submit();
    const stateCode = getAbbrev(formVal.state);
    console.log("state code --", stateCode);

    if (isNext) {
      setLoading(true);
      const naicsCode = franchisorNacisCode
        ? franchisorNacisCode.code
        : nacisCode.code;
      const token = await getAccessTokenSilently();
      const riskList = (franchiseeData.locationList || []).map(
        (item, index) => ({
          objectType: "LOCATION",
          locationNumber: index + 1,
          addressLine1: item.address,
          county: item.state,
          city: item.city,
          stateOrProvinceAbbreviation: getAbbrev(item.state),
          countryISOCode: GA_CONSTS.defaultCountry,
          postalCode: item.zip,
          locationEmployeeCount: (item.codeList || []).reduce(
            (sum, arr) => Number(sum) + Number(arr.employeeCount),
            0
          ),
          coverages: (item.codeList || []).map((codeItem) => ({
            objectType: "classCode",
            classCode: codeItem.code,
            estimatedExposure: codeItem.exposure,
          })),
        })
      );
      const applicationData = {
        application: {
          input: {
            submission: {
              insuredName: `${formVal.first_name} ${formVal?.last_name}`,
              insuredStreetAddress: formVal?.address,
              insuredCity: formVal?.city,
              insuredStateCode: stateCode,
              insuredZipCode: formVal?.zip,
              insuredCountryCode: GA_CONSTS.defaultCountry,
              producerCode: GA_CONSTS.producerCode,
              fein: formVal.tax_id,
              naicsCode,
              quotes: [
                {
                  policyEffectiveDate: format(
                    addMonths(new Date(), 1),
                    "yyyy-MM-dd"
                  ),
                  products: [
                    {
                      objectType: GA_CONSTS.productType,
                      risks: riskList,
                    },
                  ],
                },
              ],
            },
          },
        },
      };
      const result = await post(`gai/application`, applicationData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("result data ---", result);

      setLoading(false);
      const applicationId = result.data.data.newBusiness.id;
      history.push(`/admin/eligibility/${id}/${applicationId}`);
    }
  }

  function handleClose() {
    history.push(`/admin/franchisees/${id}`, { ...location?.state });
  }

  async function handleSave() {
    try {
    } catch (error) {}
  }

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  }

  function handleChangeNacis(key, value) {
    setNacisCode({
      ...nacisCode,
      [key]: value,
    });
  }

  function handleSelectNacis(key, value) {
    const codeData = value.split(" - ");
    setNacisCode({
      ...formVal,
      code: codeData[0],
      description: codeData[1],
    });
  }

  const franchisorNacisCode = useMemo(() => {
    if (franchisorData?.naics_classes) {
      const nacisCode = franchisorData?.naics_classes.find(
        (item) => item.eleType === "NAICS"
      );
      return nacisCode;
    } else {
      return null;
    }
  }, [franchisorData]);

  const isNext = useMemo(() => {
    return nacisCode?.code || franchisorNacisCode?.code;
  }, [nacisCode, franchisorNacisCode]);

  return (
    <div className="admin_appetite">
      <QuoteProgressBar step={1}></QuoteProgressBar>
      <QuoteHeader
        franchiseeData={franchiseeData}
        requirement={franchisorData}
        user={user}
      ></QuoteHeader>
      <div className="appetite_container">
        <QuoteStepper step={1}></QuoteStepper>
        <div className="right-content">
          <div className="workers-compensation-box">
            <Card title="Insurance Recommendations">
              {error && (
                <Alert message={error} className="form-error" type="error" />
              )}
              {franchisorNacisCode && (
                <div className="">
                  <label>NAICS Code</label>
                  <div className="nacis-code-container">
                    <Input value={franchisorNacisCode.code} disabled />
                    <Input value={franchisorNacisCode.description} disabled />
                  </div>
                </div>
              )}
              {!franchisorNacisCode && (
                <>
                  <Alert
                    type="warning"
                    message="There is no associated NAICS Code. You need to select one."
                  />
                  <div className="">
                    <label>NAICS Code</label>
                    <ClassCodeInput
                      typeVal="NAICS"
                      codeVal={nacisCode.code}
                      descVal={nacisCode.description}
                      setCodeVal={(val) => handleChangeNacis("code", val)}
                      setDescVal={(val) =>
                        handleChangeNacis("description", val)
                      }
                      setSelect={(val) => handleSelectNacis("main", val)}
                    />
                  </div>
                </>
              )}
            </Card>
            <Card title="Account">
              <div className="form-group-container">
                <div className="line-item-container">
                  <Input
                    title="First Name"
                    type="text"
                    value={formVal.first_name}
                    disabled
                  />
                </div>
                <div className="line-item-container">
                  <Input
                    title="Entity Name"
                    type="text"
                    value={formVal.entity_name}
                    disabled
                  />
                </div>
              </div>

              <div className="form-group-container">
                <div className="line-item-container">
                  <Input
                    title="Last Name"
                    type="text"
                    value={formVal.last_name}
                    disabled
                  />
                </div>
                <div className="line-item-container">
                  <Input
                    title="Street Address"
                    type="text"
                    value={formVal.address}
                    disabled
                  />
                </div>
              </div>

              <div className="form-group-container">
                <div className="line-item-container">
                  <Input
                    title="Email"
                    type="text"
                    value={formVal.email}
                    disabled
                  />
                </div>
                <div className="form-group-three-container">
                  <Input
                    title="City"
                    type="text"
                    value={formVal.city}
                    disabled
                  />
                  <Input
                    title="State"
                    type="text"
                    value={formVal.state}
                    disabled
                  />
                  <Input title="Zip" type="text" value={formVal.zip} disabled />
                </div>
              </div>

              <div className="form-group-container">
                <div className="line-item-container">
                  <Input
                    title="Telephone"
                    type="text"
                    inputType="TYPE_INPUT_PHONE"
                    value={formVal.telephone}
                    disabled
                  />
                </div>
                <div className="form-group-container">
                  <Input
                    title="Opening Date"
                    type="text"
                    value={formVal.opening_date}
                    disabled
                  />
                  <Input
                    title="Tax ID"
                    type="text"
                    inputType={TYPE_INPUT_TAX_ID}
                    value={formVal.tax_id}
                    onChange={(evt) => handleChange("tax_id", evt.target.value)}
                  />
                </div>
              </div>
            </Card>
            <UnderWriting franchiseeData={franchiseeData} disabled={true} />
          </div>
          <QuoteAction
            handleClose={handleClose}
            handleSave={handleSave}
            handleBack={handleBack}
            handleNext={handleNext}
            nextDisable={!isNext}
          ></QuoteAction>
        </div>
      </div>
      <Loading loading={loading} />
    </div>
  );
}

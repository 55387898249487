import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Input as AntInput, Select, Space } from "antd";
import { post } from "../../utils/axios";
import "./style.scss";
const { Search } = AntInput;
const { Option } = Select;

export default function SpectrumCode(props) {
  const { onChange, titleFlag = true } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [optionList, setOptionList] = useState(props.optionList);
  const [parentCode, setParentCode] = useState(props.parentCode);
  const [childCode, setChildCode] = useState(props.childCode);

  function handleChangeParent(classItem) {
    const selectedItem = optionList.find(
      (item) => `${item.spectrumCode}-${item.className}` === classItem
    );
    setParentCode(selectedItem);
    setChildCode(null);
    onChange("parentCode", selectedItem);
    onChange("childCode", null);
  }

  function handleChangeChild(spectrumCode) {
    const answerItem = parentCode?.answerSet.find(
      (item) => `${item.spectrumCode}-${item.className}` === spectrumCode
    );
    setChildCode(answerItem);
    onChange("childCode", answerItem);
  }

  async function handleSearchCode(str) {
    try {
      setLoading(true);

      const token = await getAccessTokenSilently();
      const result = await post(
        `/hartford/sc-code`,
        {
          searchStr: str,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const optionListUpdate = result?.data?.data || [];
      optionListUpdate.forEach((item) => {
        if (!item.question) {
          item.answerSet = null;
        }
      });

      setOptionList(optionListUpdate);
      onChange("optionList", optionListUpdate);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  console.log("parent code --", parentCode);

  return (
    <div className="spectrum_class">
      {titleFlag && (
        <div>
          <p className="input-title">Spectrum Class Code</p>
        </div>
      )}
      <div className="form-group-container">
        <Search
          placeholder="Class Name"
          size="large"
          onSearch={handleSearchCode}
          loading={loading}
          enterButton
        />
        {optionList && (
          <Select
            value={
              parentCode
                ? `${parentCode?.spectrumCode}-${parentCode?.className}`
                : null
            }
            size="large"
            onChange={handleChangeParent}
          >
            {optionList.map((item) => (
              <Option value={`${item.spectrumCode}-${item.className}`}>
                {item.spectrumCode}-{item.className}
              </Option>
            ))}
          </Select>
        )}
        {parentCode?.answerSet && (
          <Select
            size="large"
            value={
              childCode
                ? `${childCode?.spectrumCode}-${childCode?.className}`
                : null
            }
            onChange={handleChangeChild}
          >
            {parentCode?.answerSet.map((item) => (
              <Option value={`${item.spectrumCode}-${item.className}`}>
                {item.spectrumCode}-{item.className}
              </Option>
            ))}
          </Select>
        )}
      </div>
    </div>
  );
}

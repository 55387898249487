import React from "react";
import "./style.scss";

export default function ButtonGroup({ buttonList }) {
  return (
    <div className="btn_group">
      {buttonList.map((item, index) => (
        <div
          key={`key-btn-group-${index}`}
          className={
            item?.disabled
              ? "btn_group-container-disabled"
              : "btn_group-container"
          }
          onClick={(evt) => {
            if (!item?.disabled) {
              item.onClick(evt);
            }
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
}

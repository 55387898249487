import React, { useEffect, useState } from "react";
import { Card, Input, AddressInput, Loading } from "../../../../components";
import { Checkbox, Radio, Button } from "antd";
import "./style.scss";
import { useAuth0 } from "@auth0/auth0-react";
import LocationBlock from "./location-block";
import BopInforBlock from "./bop-infor-block";
import OfficeBlock from "./office-block";
import EmployeeLiability from "./employee-liability";
import UnderwritingInfor from "./underwriting-infor";
import { put } from "../../../../utils/axios";
import { notify, validateSpecialInputFields } from "../../../../utils/common";
import _ from "lodash";
import {
  COVERAGE_KEY_PROPERTY,
  COVERAGE_KEY_WORKERS_COMP
} from "../../../../assets/const/fim-integration";
import BuildOutInfoBlock from "./build-out-info-block";
import DriverInfoBlockForm from "./driver-info-block-form";
import UnderWritingQuestion from "../../franchisee-overview/underwriting-question";
import RequestClientForm from "./request-client";
import ReportBlock from "./report-block";
import EntityBlock from "./entity-block";
import POCBlock from "./poc-block";
import VehicleInfoBlockForm from "./vehicle-info-block-form";
import EquipmentBlockForm from "./equipment-block-form";
import UnScheduledEquipmentBlockForm from "./unscheduled-equipment-block-form";
import CommonLocForm from "./common-loc-form";
import OfficeBlockForm from "./office-block-form";
import SubcontractorBlockForm from "./subcontractor-block-form";
import PayrollBlockForm from "./payroll-block-form";
import PropertyBlockForm from "./property-block-form";
import { VIEW_SOURCE_ADMIN } from "@assets/const/ui";

export function buildCustomQuestions(
  franchisorQuestions = [],
  franchiseeAnswers = []
) {
  const franchiseeAnswerMap = {};
  for (const qGroup of franchiseeAnswers || []) {
    for (const question of qGroup.questions) {
      franchiseeAnswerMap[question.title] = question.value;
    }
  }
  // Loop through franchisor questions and fill in franchisee's answers for the questions.
  const result = [];
  for (const qGroup of franchisorQuestions) {
    const questions = qGroup.questions.map((q) =>
      franchiseeAnswerMap[q.title]
        ? { ...q, value: franchiseeAnswerMap[q.title] }
        : q
    );
    result.push({ ...qGroup, questions });
  }
  return result;
}

export default function UnderWriting(props) {
  const {
    franchiseeData,
    franchisorData,
    setFranchiseeData,
    disabled = false
  } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [officeList, setOfficeList] = useState([
    {
      full_name: "",
      name_type: false,
      payroll: "",
      class_code: "",
      owner_ship: ""
    }
  ]);
  const [locationList, setLocationList] = useState([
    {
      address: "",
      city: "",
      state: "",
      zip: "",
      codeList: [
        {
          code: "",
          exposure: "",
          employeeCount: ""
        }
      ]
    }
  ]);
  const [bopInfor, setBopInfor] = useState({
    totalArea: "",
    yearBuilt: "",
    constructionTypeCode: "",
    numberOfStories: "",
    includeBuildingCoverage: false,
    bppLimit: ""
  });

  const [liability, setLiability] = useState({
    injury: "",
    policy: "",
    employee: "",
    annual: ""
  });

  const [information, setInformation] = useState({
    federalId: "",
    modifier: "",
    totalNumber: "",
    riskId: "",
    subrogation: false
  });

  const [buildOutInfo, setBuildOutInfo] = useState({});
  const [driverList, setDriverList] = useState([
    {
      firstName: "",
      lastName: "",
      driversLicenseNumber: "",
      stateOfLicense: "",
      dateOfBirth: ""
    }
  ]);
  const [vehicleList, setVehicleList] = useState([
    {
      year: "",
      make: "",
      model: "",
      vin: ""
    }
  ]);
  const [customQuestions, setCustomQuestions] = useState([]);

  useEffect(() => {
    if (franchiseeData) {
      setOfficeList(franchiseeData.officeList || []);
      setLocationList(franchiseeData.locationList || []);
      setBopInfor(franchiseeData.bopInfor || {});
      setLiability(franchiseeData.employeeLiability || {});
      setInformation(franchiseeData.underwritingInformation || {});
      setBuildOutInfo(franchiseeData.buildOutInfo || {});
      setDriverList(
        franchiseeData.driverList || [
          {
            firstName: "",
            lastName: "",
            driversLicenseNumber: "",
            stateOfLicense: "",
            dateOfBirth: ""
          }
        ]
      );
      setVehicleList(
        franchiseeData.vehicleList || [
          {
            year: "",
            make: "",
            model: "",
            vin: ""
          }
        ]
      );
      setCustomQuestions(
        buildCustomQuestions(
          franchisorData.custom_questions,
          franchiseeData.customQuestions
        )
      );
    }
  }, [franchiseeData, franchisorData]);

  function handleChangeOffice(officeData) {
    setOfficeList([...officeData]);
  }

  function handleChangeLocation(locationData) {
    setLocationList([...locationData]);
  }

  function handleChangeBop(bopInfor) {
    setBopInfor({ ...bopInfor });
  }

  async function handleSave() {
    const err = validateSpecialInputFields(information);
    if (err) {
      notify("Form Invalid", err, "error");
      return;
    }

    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          officeList: officeList,
          locationList: locationList,
          employeeLiability: liability,
          underwritingInformation: information,
          bopInfor: bopInfor,
          driverList,
          vehicleList,
          buildOutInfo,
          customQuestions
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <div className="admin_franchisee_under">
      {props.viewSource === VIEW_SOURCE_ADMIN && (
        <RequestClientForm
          franchiseeData={franchiseeData}
          setFranchiseeData={setFranchiseeData}
        />
      )}
      {props.viewSource === VIEW_SOURCE_ADMIN && (
        <ReportBlock franchiseeData={franchiseeData} />
      )}
      <EntityBlock
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
      <CommonLocForm
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
      <POCBlock
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
      <Card title={"Additional Questions"}>
        <UnderWritingQuestion
          onChange={setCustomQuestions}
          isCustom={true}
          customQuestions={customQuestions}
          hideSaveButton={true}
          allowChangeQuestions={false}
        />
      </Card>
      {/* <Card title={"Workers Compensation"}>
        <div className="form-group-container">
          <EmployeeLiability
            data={liability}
            disabled={disabled}
            onChange={setLiability}
          />
          <UnderwritingInfor
            data={information}
            disabled={disabled}
            onChange={setInformation}
          />
        </div>
        <LocationBlock
          franchiseeData={franchiseeData}
          disabled={disabled}
          data={locationList}
          coverageType={COVERAGE_KEY_WORKERS_COMP}
          codeData={franchisorData.naics_classes || []}
          onChange={handleChangeLocation}
        />
      </Card> */}
      {/* <Card title={"Property Info"}>
        <LocationBlock
          franchiseeData={franchiseeData}
          disabled={disabled}
          data={locationList}
          coverageType={COVERAGE_KEY_PROPERTY}
          onChange={handleChangeLocation}
        />
      </Card> */}
      <PropertyBlockForm
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
      <PayrollBlockForm
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
      <SubcontractorBlockForm
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
      <OfficeBlockForm
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
      <VehicleInfoBlockForm
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
      <DriverInfoBlockForm
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
      <BuildOutInfoBlock
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />

      <EquipmentBlockForm
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
      <UnScheduledEquipmentBlockForm
        franchiseeData={franchiseeData}
        setFranchiseeData={setFranchiseeData}
      />
      <Loading loading={loading} />
    </div>
  );
}

import React, { useState } from "react";
import "./style.scss";
import ImageUploadFolder from "@assets/images/upload-folder.png";
import { Button, Table } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import Papa from "papaparse";
import { useAuth0 } from "@auth0/auth0-react";
import { Loading, Alert } from "../index";
import { post } from "@utils/axios";
import { useAppState } from "@context";
import { getHostTag } from "@utils/host";
import { filterValue } from "@utils/common";
import {
  VIEW_MODE_UPLOAD_FORM,
  VIEW_MODE_CONTACT_LIST
} from "@assets/const/ui";

const IMPORT_METHOD_OVERWRITE = "overwrite";
const IMPORT_METHOD_NONE = "none";

function UploadFranchiseeForm(props) {
  const [fileList, setFileList] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const { franchisorAdmin } = useAppState();
  const { handleNavigate, viewMode, setViewMode } = props;

  function handleUpload() {
    Papa.parse(fileList[0], {
      complete: handleUploadData,
      header: true
    });
  }

  async function handleUploadData(result) {
    setViewMode(VIEW_MODE_CONTACT_LIST);
    const fileData = result?.data
      ? result.data
          .filter((item) => item.email)
          .map((item, index) => ({ ...item, key: `key-item-${index}` }))
      : [];
    setDataSource(fileData);
    setCheckedList(fileData);
  }

  function handleFile(evt) {
    setFileList(evt.target.files);
  }

  function renderFiles() {
    for (let index = 0; index < fileList.length; index++) {
      return (
        <div className="file-item">
          {fileList[0].name} <CheckOutlined />
        </div>
      );
    }
  }

  function handleCancel() {
    setViewMode(VIEW_MODE_UPLOAD_FORM);
  }

  async function handleImport(method) {
    if (checkedList && checkedList.length > 0) {
      setLoading(true);
      try {
        const params = checkedList
          .filter((item) => filterValue(item.email))
          .map((item) => {
            const paramData = {
              first_name: filterValue(item.first_name),
              last_name: filterValue(item.last_name),
              email: item.email.toLowerCase(),
              telephone: filterValue(item.telephone),
              address: filterValue(item.address),
              city: filterValue(item.city),
              state: filterValue(item.state),
              zip: filterValue(item.zip),
              dba: filterValue(item.dba),
              entity_name: filterValue(item.entity_name),
              centerName: filterValue(item.center_name),
              opening_date: filterValue(item.opening_date),
              tax_id: filterValue(item.tax_id),
              host: props.host || getHostTag(),
              finalData: {
                commercial_general_liability_effective_date: filterValue(
                  item.gl_effective_date
                ),
                commercial_general_liability_expire_date: filterValue(
                  item.gl_expire_date
                ),
                automotive_liability_effective_date: filterValue(
                  item.au_effective_date
                ),
                automotive_liability_expire_date: filterValue(
                  item.au_expire_date
                ),
                workers_compensation_effective_date: filterValue(
                  item.wc_effective_date
                ),
                workers_compensation_expire_date: filterValue(
                  item.wc_expire_date
                ),
                umbrella_subrogration_effective_date: filterValue(
                  item.um_effective_date
                ),
                umbrella_subrogration_expire_date: filterValue(
                  item.um_expire_date
                )
              }
            };

            if (
              item.first_name_2 ||
              item.last_name_2 ||
              item.email_2 ||
              item.telephone_2
            ) {
              paramData["poc_contacts"] = [
                {
                  firstName: filterValue(item.first_name_2),
                  lastName: filterValue(item.last_name_2),
                  email: filterValue((item.email_2 || "").toLowerCase()),
                  telephone: filterValue(item.telephone_2)
                }
              ];
            }

            return paramData;
          });
        const token = await getAccessTokenSilently();
        const result = await post(
          `contact/create-list`,
          {
            contacts: params,
            requirementId: props.requirementId,
            method
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        setLoading(false);
        if (method === IMPORT_METHOD_NONE) {
          if (result?.data?.errors && result?.data.errors.length > 0) {
            setErrorMsg(
              `${result.data.errors.join(
                ", "
              )} are already used. Will you overwrite?`
            );
          } else {
            const { handleNavigate } = props;
            handleNavigate();
          }
        } else {
          const { handleNavigate } = props;
          handleNavigate();
        }
      } catch (e) {
        setLoading(false);
      }
    }
  }

  const rowSelection = {
    selectedRowKeys: checkedList.map((item) => item.key),
    onChange: (selectedRowKeys, selectedRows) => {
      setCheckedList(selectedRows);
    }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name"
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Phone",
      dataIndex: "telephone",
      key: "telephone"
    },
    {
      title: "Entity Name",
      dataIndex: "entity_name",
      key: "entity_name"
    },
    {
      title: "DBA",
      dataIndex: "dba",
      key: "dba"
    },
    {
      title: "Street Address",
      dataIndex: "address",
      key: "address"
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city"
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state"
    },
    {
      title: "Zip",
      dataIndex: "zip",
      key: "zip"
    },
    {
      title: "Opening Date",
      dataIndex: "opening_date",
      key: "opening_date"
    },
    {
      title: "Tax ID",
      dataIndex: "tax_id",
      key: "tax_id"
    },
    {
      title: "Liability Effective Date",
      dataIndex: "gl_effective_date",
      key: "gl_effective_date"
    },
    {
      title: "Liability Expire Date",
      dataIndex: "gl_expire_date",
      key: "gl_expire_date"
    },
    {
      title: "Auto Effective Date",
      dataIndex: "au_effective_date",
      key: "au_effective_date"
    },
    {
      title: "Auto Expire Date",
      dataIndex: "au_expire_date",
      key: "au_expire_date"
    },
    {
      title: "Workers Comp Effective Date",
      dataIndex: "wc_effective_date",
      key: "wc_effective_date"
    },
    {
      title: "Workers Comp Expire Date",
      dataIndex: "wc_expire_date",
      key: "wc_expire_date"
    },
    {
      title: "Umbrella Effective Date",
      dataIndex: "um_effective_date",
      key: "um_effective_date"
    },
    {
      title: "Umbrella Expire Date",
      dataIndex: "um_expire_date",
      key: "um_expire_date"
    },
    {
      title: "Center Name/Location Number",
      dataIndex: "center_name",
      key: "center_name"
    },
    {
      title: "Contact First Name",
      dataIndex: "first_name_2",
      key: "first_name_2"
    },
    {
      title: "Contact Last Name",
      dataIndex: "last_name_2",
      key: "last_name_2"
    },
    {
      title: "Contact Email",
      dataIndex: "email_2",
      key: "email_2"
    },
    {
      title: "Contact Telephone",
      dataIndex: "telephone_2",
      key: "telephone_2"
    }
  ];

  function handleBack() {
    const { handleViewMode } = props;
    handleViewMode("VIEW_MODE_LIST");
  }

  return (
    <div className="upload_mo">
      {viewMode === VIEW_MODE_CONTACT_LIST && (
        <div className="upload_mo-list">
          <div className="upload_mo-list-con">
            {errorMsg && (
              <Alert
                message={errorMsg}
                onOk={() => handleImport(IMPORT_METHOD_OVERWRITE)}
                onCancel={handleNavigate}
                okLabel="Yes"
                cancelLabel="Cancel"
              />
            )}
            <div className="upload_mo-list-con-wrapper">
              <div className="upload_mo-list-con-wrapper-tool">
                <a onClick={() => setCheckedList(dataSource)}>Select All</a>
                <a onClick={() => setCheckedList([])}>Deselect All</a>
              </div>
              <Table
                className="upload_mo-list-con-wrapper-table"
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataSource}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      // handleNavDetail(record.id);
                    }
                  };
                }}
              />
            </div>
          </div>

          <div className="upload_mo-list-footer">
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            {!errorMsg && (
              <Button
                type="primary"
                className="save-btn"
                onClick={() => handleImport(IMPORT_METHOD_NONE)}
                disabled={checkedList.length === 0}
              >
                Import
              </Button>
            )}
          </div>
          <Loading loading={loading} />
        </div>
      )}
      {viewMode === VIEW_MODE_UPLOAD_FORM && (
        <div className="upload_mo-up">
          <div className="upload-container">
            <img src={ImageUploadFolder} />
            <p>
              Drag and Drop Your CSV Here
              <br />
              or
            </p>
            {fileList.length === 0 && (
              <Button className="btn-add-file" onClick={handleUpload}>
                Add Files
              </Button>
            )}
            {renderFiles()}
            <input type="file" onChange={handleFile} />
          </div>
          {fileList.length !== 0 && (
            <Button
              type="primary"
              className="upload_mo-up-btn"
              onClick={handleUpload}
            >
              Upload
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default UploadFranchiseeForm;

import Alert from "@components/alert";
import { buildCustomQuestions } from "../../admin-board/franchisee-detail/under-writing";
import { post, put, get } from "@utils/axios";
import { getHostTag } from "@utils/host";
import { AutoComplete, Button, Checkbox, Spin } from "antd";
import _, { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import "./style.scss";
import { extractAPIErrMsg, notify } from "@utils/common";
import ProfileForm from "./profile-form";
import CountDown from "./count-down";
import { convertTimeStrToMilli } from "@utils/time";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { formSchema } from "../add-franchisee/form-schema";
import VehicleDriverSection from "./vehicle-driver-section";
import BuildOutSection from "./build-out-section";
import PropertySection from "./prop-section";
import CustomQuestionsSection from "./custom-questions-section";
import SuccessPage from "./success-page";
import EmailVerification from "./email-verification";
import { DO_NOT_CONTACT_OPTION_IS_LEAD } from "@assets/const/status";

export default function AddFranchisee({ franchisors }) {
  const form = useForm({
    resolver: zodResolver(formSchema),
    mode: "all",
    defaultValues: {
      vehicleFormVal: [
        {
          year: "",
          make: "",
          model: "",
          value: "",
          vin: "",
          equipment: "",
          parkHome: null,
          trackDevice: null,
          garageAddress: "",
          garageCity: "",
          garageState: "",
          garageZip: ""
        }
      ],
      driverFormVal: [
        {
          firstName: "",
          lastName: "",
          driversLicenseNumber: "",
          stateOfLicense: "",
          dateOfBirth: ""
        }
      ]
    }
  });
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    trigger,
    register,
    unregister,
    formState: { errors, isSubmitting }
  } = form;
  const { profileFormVal, vehicleFormVal, driverFormVal, buildOutFormVal } =
    getValues();
  const [selectedFranchisor, setSelectedFranchisor] = useState({});
  const [foundFranchisee, setFoundFranchisee] = useState({});
  const [lookingUpFranchisee, setLookingUpFranchisee] = useState(false);
  const [showForm, setShowForm] = useState({});

  // @todo - migrate property form to use react-hook-form and zod.
  const [propFormVal, setPropFormVal] = useState([
    {
      address: "",
      city: "",
      state: "",
      zip: "",
      codeList: [
        {
          code: "",
          exposure: "",
          employeeCount: ""
        }
      ]
    }
  ]);

  const [customQuestionsFormVal, setCustomQuestionsFormVal] = useState([]);
  const [emailVerified, setEmailVerified] = useState(false);
  const [applicationSucceeded, setApplicationSucceeded] = useState(false);
  const [tempAccessToken, setTempAccessToken] = useState({
    data: "",
    expiresIn: ""
  });
  const [subscribeToEmailUpdates, setSubscribeToEmailUpdates] = useState(false);
  const [subscribeToTextUpdates, setSubscribeToTextUpdates] = useState(false);
  const [consultantData, setConsultantData] = useState(null);

  useEffect(() => {
    if (selectedFranchisor?.consultantId && tempAccessToken.data !== "") {
      handleLoadConsultant();
    }
  }, [selectedFranchisor, tempAccessToken]);

  async function handleLoadConsultant() {
    try {
      const resultConsultant = await get(
        `user/${selectedFranchisor?.consultantId}`,
        {
          headers: {
            "x-internal-api-access-token": tempAccessToken.data
          }
        }
      );

      setConsultantData(resultConsultant?.data?.data);
    } catch (error) {}
  }

  function resetVerification() {
    setEmailVerified(false);
    resetForms();
  }

  function resetForms() {
    setFoundFranchisee({});
    setPropFormVal([
      {
        address: "",
        city: "",
        state: "",
        zip: "",
        codeList: [
          {
            code: "",
            exposure: "",
            employeeCount: ""
          }
        ]
      }
    ]);
    reset();
    setShowForm({});
  }

  function handleCQSectionAnswer(sIndex, newSectionData) {
    const customQuestionsFormValCopy = _.cloneDeep(customQuestionsFormVal);
    customQuestionsFormValCopy[sIndex].questions = newSectionData;
    setCustomQuestionsFormVal(customQuestionsFormValCopy);
  }

  const expiresInMillis = useMemo(
    () =>
      tempAccessToken.expiresIn
        ? convertTimeStrToMilli(tempAccessToken.expiresIn)
        : 0,
    [tempAccessToken.expiresIn]
  );

  const options = useMemo(
    () =>
      franchisors
        .filter((f) => f.dba)
        .sort((itemA, itemB) => itemA.dba.localeCompare(itemB.dba))
        .map((f, index) => ({
          key: index,
          value: `${f.dba}`,
          data: f
        })),
    []
  );

  function handleShowFormToggle(formType, value) {
    setShowForm((prev) => ({
      ...prev,
      [formType]: value
    }));
  }

  function handlePrefillForm() {
    setValue(
      "profileFormVal",
      foundFranchisee.isInsureDiff
        ? {
            ...foundFranchisee,
            ...foundFranchisee.insuranceData
          }
        : foundFranchisee
    );
    setPropFormVal(
      _.isEmpty(foundFranchisee.locationList)
        ? [
            {
              address: "",
              city: "",
              state: "",
              zip: "",
              codeList: [
                {
                  code: "",
                  exposure: "",
                  employeeCount: ""
                }
              ]
            }
          ]
        : foundFranchisee.locationList
    );
    if (!_.isEmpty(foundFranchisee.vehicleList)) {
      setValue("vehicleFormVal", foundFranchisee.vehicleList);
    }
    if (!_.isEmpty(foundFranchisee.driverList)) {
      setValue("driverFormVal", foundFranchisee.driverList);
    }
    setValue("buildOutFormVal", foundFranchisee.buildOutInfo || {});
    setCustomQuestionsFormVal(
      buildCustomQuestions(
        selectedFranchisor.custom_questions,
        foundFranchisee.customQuestions
      )
    );
    setSubscribeToEmailUpdates(
      foundFranchisee.subscribeToEmailUpdates || false
    );
    setSubscribeToTextUpdates(foundFranchisee.subscribeToTextUpdates || false);
    trigger();
  }
  async function handleFranchisorSelect(selectedOption) {
    if (!_.isEqual(selectedOption.data, selectedFranchisor)) {
      resetVerification();
    }
    setSelectedFranchisor(selectedOption.data);
    setCustomQuestionsFormVal(selectedOption.data.custom_questions || []);
  }

  async function handleLookupFranchisee(email, tempAccessToken) {
    try {
      setLookingUpFranchisee(true);
      const fetchResults = await Promise.all([
        lookupFranchiseePromise(
          {
            email,
            requirementId: selectedFranchisor._id
          },
          tempAccessToken
        ),
        lookupFranchiseePromise(
          {
            "insuranceData.email": email,
            requirementId: selectedFranchisor._id
          },
          tempAccessToken
        )
      ]);
      for (const result of fetchResults) {
        if (result.status === 200) {
          if (result.data?.data.length > 0) {
            const franchisee = result.data.data[0];
            setFoundFranchisee(franchisee);
            return;
          }
        } else {
          resetForms();
          throw new Error(`Received a non-200 status code: ${result.status}`);
        }
      }
    } catch (error) {
      const errMsg = `Failed to look up franchisee with email ${email} - ${extractAPIErrMsg(
        error,
        "please contact support."
      )}.`;
      console.log(error);
      notify("Error", errMsg);
    } finally {
      setLookingUpFranchisee(false);
    }
  }

  async function lookupFranchiseePromise(query, tempAccessToken) {
    return post(
      `admin/franchisee`,
      {
        host: getHostTag(),
        franchisorId: selectedFranchisor._id,
        query
      },
      {
        headers: {
          "x-internal-api-access-token": tempAccessToken
        }
      }
    );
  }

  async function handleVerifyCode(verificationCode) {
    try {
      const result = await post("/auth0/verify-email-code", {
        email: getValues("profileFormVal.email"),
        code: verificationCode
      });
      setTempAccessToken({
        data: result.data.data.tempAccessToken,
        expiresIn: result.data.data.expiresIn
      });
      setEmailVerified(true);
      return result.data.data.tempAccessToken;
    } catch (error) {
      const errMsg = `Wrong verification code ${verificationCode} - ${extractAPIErrMsg(
        error,
        "please contact support if you think this is a mistake."
      )}.`;
      setEmailVerified(false);
      console.log(error);
      notify("Error", errMsg);
    }
  }

  async function handleQuoteNow() {
    try {
      if (_.isEmpty(foundFranchisee)) {
        await createNewFranchisee();
      } else {
        await updateFranchisee();
      }
      setApplicationSucceeded(true);
      notify(
        "Success!",
        "You'll be receiving an email very soon to proceed with the quoting process!",
        "success"
      );
    } catch (error) {
      const errMsg = `Failed to quote - ${extractAPIErrMsg(
        error,
        "please contact support"
      )}.`;
      console.log("failed to update/create Franchisee data:", error);
      notify("Error", errMsg);
    }
  }

  async function updateFranchisee() {
    await put(
      `contact/${foundFranchisee._id}`,
      {
        ...(foundFranchisee.isInsureDiff
          ? {
              insuranceData: profileFormVal
            }
          : {
              first_name: profileFormVal.first_name,
              last_name: profileFormVal.last_name,
              email: profileFormVal.email.toLowerCase(),
              telephone: profileFormVal.telephone,
              entity_name: profileFormVal.entity_name,
              entity_type: profileFormVal.entity_type,
              centerName: profileFormVal.centerName,
              annualRevenue: profileFormVal.annualRevenue,
              dba: profileFormVal.dba,
              tax_id: profileFormVal.tax_id,
              address: profileFormVal.address,
              city: profileFormVal.city,
              state: profileFormVal.state,
              zip: profileFormVal.zip
            }),
        doNotContact: DO_NOT_CONTACT_OPTION_IS_LEAD,
        ...(showForm["property"] ? { locationList: propFormVal } : {}),
        ...(showForm["vehicle"] ? { vehicleList: vehicleFormVal } : {}),
        ...(showForm["vehicle"] ? { driverList: driverFormVal } : {}),
        ...(showForm["buildOut"] ? { buildOutInfo: buildOutFormVal } : {}),
        customQuestions: customQuestionsFormVal,
        quoteStatus: true,
        subscribeToEmailUpdates,
        subscribeToTextUpdates,
        host: selectedFranchisor?.host || getHostTag()
      },
      {
        headers: {
          "x-internal-api-access-token": tempAccessToken.data
        }
      }
    );
  }

  async function createNewFranchisee() {
    await post(
      `contact`,
      {
        first_name: profileFormVal.first_name,
        last_name: profileFormVal.last_name,
        email: profileFormVal.email.toLowerCase(),
        telephone: profileFormVal.telephone,
        entity_name: profileFormVal.entity_name,
        entity_type: profileFormVal.entity_type,
        centerName: profileFormVal.centerName,
        annualRevenue: profileFormVal.annualRevenue,
        dba: profileFormVal.dba,
        tax_id: profileFormVal.tax_id,
        address: profileFormVal.address,
        city: profileFormVal.city,
        state: profileFormVal.state,
        zip: profileFormVal.zip,
        ...(showForm["property"] ? { propertyList: propFormVal } : {}),
        ...(showForm["vehicle"] ? { vehicleList: vehicleFormVal } : {}),
        ...(showForm["vehicle"] ? { driverList: driverFormVal } : {}),
        ...(showForm["buildOut"] ? { buildOutInfo: buildOutFormVal } : {}),
        customQuestions: customQuestionsFormVal,
        requirementId: selectedFranchisor._id,
        doNotContact: DO_NOT_CONTACT_OPTION_IS_LEAD,
        quoteStatus: true,
        subscribeToEmailUpdates,
        subscribeToTextUpdates,
        host: selectedFranchisor?.host || getHostTag()
      },
      {
        headers: {
          "x-internal-api-access-token": tempAccessToken.data
        }
      }
    );
  }

  function renderFranchiseeFoundAlert() {
    const franchiseeFound = !_.isEmpty(foundFranchisee);
    if (!franchiseeFound) {
      return <></>;
    }
    return (
      <div className="alert-confirmation-line">
        <div>
          <Alert
            status="success"
            message={`Franchisee with email ${profileFormVal.email} found in Rikor system.`}
            okLabel="Prefill my application"
            onOk={handlePrefillForm}
          />
        </div>
      </div>
    );
  }

  function shouldShowSection(sectionKey) {
    return (
      (selectedFranchisor.standard_questions || []).find(
        (q) => q.key === sectionKey
      )?.enabled || true // default to show.
    );
  }

  return (
    <>
      <form
        noValidate
        onSubmit={handleSubmit(handleQuoteNow, (err) => {
          // We don't need to validate these forms unless they are "shown".
          if (err.driverFormVal && !showForm["vehicle"]) {
            delete err.driverFormVal;
          }
          if (err.vehicleFormVal && !showForm["vehicle"]) {
            delete err.vehicleFormVal;
          }
          if (err.buildOutFormVal && !showForm["buildOut"]) {
            delete err.buildOutFormVal;
          }
          if (isEmpty(err)) {
            handleQuoteNow();
          } else {
            console.log(err);
            notify(
              "There are errors in the application. Please make sure you've filled every required field."
            );
          }
        })}
      >
        {applicationSucceeded ? (
          <SuccessPage calDiscoverLink={consultantData?.link} />
        ) : (
          <div className="franchisor-selection">
            {tempAccessToken.expiresIn && emailVerified && (
              <div className="session-expiration-alert">
                <CountDown
                  initialValueInMillis={expiresInMillis}
                  onCountDownEnd={resetVerification}
                />
              </div>
            )}
            <p>Which franchisor is this for?</p>
            <AutoComplete
              size="large"
              style={{ width: 500 }}
              options={options}
              allowClear={true}
              placeholder="Search for the franchisor you're looking for"
              onClear={() => {
                setSelectedFranchisor({});
              }}
              onChange={(value) => {
                if (!value) {
                  setSelectedFranchisor({});
                }
              }}
              onSelect={(_, option) => {
                handleFranchisorSelect(option);
              }}
              filterOption={(inputValue, option) => {
                if (option && option.value) {
                  return (
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue?.toUpperCase()) !== -1
                  );
                }
                return false;
              }}
            />

            {!_.isEmpty(selectedFranchisor) && (
              <>
                {selectedFranchisor.personal_infor_logo && (
                  <div className="img-container">
                    <img
                      className="img-container-img"
                      src={selectedFranchisor.personal_infor_logo}
                    />
                  </div>
                )}

                <div className="franchisee-form">
                  <EmailVerification
                    key={selectedFranchisor._id}
                    form={form}
                    emailVerified={emailVerified}
                    onVerify={async (code) => {
                      const tempAccessToken = await handleVerifyCode(code);
                      if (tempAccessToken) {
                        await handleLookupFranchisee(
                          getValues("profileFormVal.email"),
                          tempAccessToken
                        );
                      }
                    }}
                    onReset={resetVerification}
                  />
                  {lookingUpFranchisee && (
                    <div className="looking-up-franchisee-spin">
                      <Spin />
                    </div>
                  )}
                  {emailVerified && (
                    <>
                      {renderFranchiseeFoundAlert()}
                      <div>
                        <ProfileForm form={form} rhfKey="profileFormVal" />
                        {shouldShowSection("buildOut") && (
                          <BuildOutSection
                            form={form}
                            show={showForm["buildOut"]}
                            onShowToggle={(e) => {
                              handleShowFormToggle("buildOut", e.target.value);
                            }}
                          />
                        )}
                        {shouldShowSection("location") && (
                          <PropertySection
                            franchiseeData={foundFranchisee}
                            formVal={propFormVal}
                            onChange={setPropFormVal}
                            show={showForm["property"]}
                            onShowToggle={(e) =>
                              handleShowFormToggle("property", e.target.value)
                            }
                          />
                        )}
                        {shouldShowSection("driversAndVehicles") && (
                          <VehicleDriverSection
                            form={form}
                            show={showForm["vehicle"]}
                            onShowToggle={(e) => {
                              handleShowFormToggle("vehicle", e.target.value);
                            }}
                          />
                        )}
                        {customQuestionsFormVal.map((cq, index) => (
                          <CustomQuestionsSection
                            key={`custom-question-${index}`}
                            questions={cq.questions}
                            section={cq.section}
                            show={showForm[`custom-question-${index}`]}
                            onChange={(sectionAnswers) => {
                              handleCQSectionAnswer(index, sectionAnswers);
                            }}
                            onShowToggle={(e) =>
                              handleShowFormToggle(
                                `custom-question-${index}`,
                                e.target.value
                              )
                            }
                          />
                        ))}
                        <hr />
                        <div className="footer">
                          <div>
                            <Checkbox
                              checked={subscribeToEmailUpdates}
                              onChange={(e) => {
                                setSubscribeToEmailUpdates(e.target.checked);
                              }}
                            >
                              Yes, please email me urgent insurance status
                              updates and alerts.
                            </Checkbox>
                          </div>
                          <div>
                            <Checkbox
                              checked={subscribeToTextUpdates}
                              onChange={(e) => {
                                setSubscribeToTextUpdates(e.target.checked);
                              }}
                            >
                              Yes, please allow Rikor to send me SMS text
                              messages.
                            </Checkbox>
                          </div>
                          <div style={{ marginTop: 10 }}>
                            Message and data rates may apply. Reply HELP for
                            help. Reply STOP to unsubscribe from SMS messages.
                            Up to 3 messages notifying the user of insurance
                            lapse or expiring policy and other essential
                            notifications per month.
                          </div>
                          <div>
                            View our <a href="/terms">terms & conditions</a> and{" "}
                            <a href="/privacy">privacy policy</a>.
                          </div>
                          <div>
                            <Button
                              className="quote-now-btn"
                              type="primary"
                              htmlType="submit"
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? <Spin /> : "Quote now!"}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </form>
    </>
  );
}

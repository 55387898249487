import { z } from "zod";
import { defaultSchema as profileFormSchema } from "./profile-form";
import { defaultSchema as buildOutFormSchema } from "../../../components/forms/build-out";
import { defaultSchema as driverFormSchema } from "../../../components/forms/driver";
import { defaultSchema as vehicleFormSchema } from "../../../components/forms/vehicle";
import { requiredZodErr } from "@utils/common";

export const formSchema = z.object({
  profileFormVal: profileFormSchema,
  vehicleFormVal: vehicleFormSchema.optional(),
  driverFormVal: driverFormSchema.optional(),
  buildOutFormVal: buildOutFormSchema.optional(),
  // propFormVal: z.array(
  //   z.object({
  //     address: z
  //       .string(requiredZodErr("Location address"))
  //       .nonempty(requiredZodErr("Location address")),
  //     city: z.string(requiredZodErr("City")).nonempty(requiredZodErr("City")),
  //     state: z
  //       .string(requiredZodErr("State"))
  //       .nonempty(requiredZodErr("State")),
  //     zip: z.string(requiredZodErr("Zip")).nonempty(requiredZodErr("Zip")),
  //     codeList: z.array(
  //       z.object({
  //         code: z
  //           .string(requiredZodErr("Code"))
  //           .nonempty(requiredZodErr("Code")),
  //         exposure: z
  //           .string(requiredZodErr("Exposure"))
  //           .nonempty(requiredZodErr("Exposure")),
  //         employeeCount: z.string(requiredZodErr("Employee count")).nonempty({
  //           message: requiredZodErr("Employee count"),
  //         }),
  //       })
  //     ),
  //   })
  // ),
});

import { Card, Loading } from "@components";
import React, { useState, useMemo, useEffect } from "react";
import { Select, Button, notification } from "antd";
import { FORM_EDIT, FORM_VIEW } from "@assets/const/ui";
import "./style.scss";
import ReactJson from "react-json-view";
import { format } from "date-fns";
import { getPrimaryContact } from "@utils/common";
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { put } from "@utils/axios";
import { BlockPOC } from "@components/underwriting";

const { Option } = Select;

export default function POCBlock({ franchiseeData, setFranchiseeData }) {
  const [showDiff, setShowDiff] = useState(false);
  const [version, setVersion] = useState(0);
  const [formVal, setFormVal] = useState();
  const [activeFormVal, setActiveFormVal] = useState({});
  const [viewMode, setViewMode] = useState(FORM_VIEW);
  const [loading, setLoading] = useState(false);

  const { getAccessTokenSilently, user } = useAuth0();

  const logData = useMemo(() => {
    const logPOCContacts = franchiseeData?.log_poc_contacts || [];
    return logPOCContacts.sort((itemA, itemB) =>
      new Date(itemA.updatedAt) > new Date(itemB.updatedAt) ? -1 : 1
    );
  }, [franchiseeData]);

  const versionList = useMemo(() => {
    const logPOCContacts = franchiseeData?.log_poc_contacts || [];
    return logPOCContacts
      .sort((itemA, itemB) =>
        new Date(itemA.updatedAt) > new Date(itemB.updatedAt) ? -1 : 1
      )
      .map((item) => {
        return {
          updatedAt: format(new Date(item.updatedAt), "MM/dd/yyyy hh:mm a"),
          updatedBy: item.updatedBy
        };
      });
  }, [franchiseeData]);

  useEffect(() => {
    setFormVal(handleGetFormVal());
    setActiveFormVal(handleGetFormVal());
  }, [franchiseeData]);

  function handleSwitchVersion(versionIndex) {
    setVersion(versionIndex);
    setFormVal(logData[versionIndex].data);
  }

  function handleGetFormVal() {
    const pocContacts = JSON.parse(
      JSON.stringify(franchiseeData?.poc_contacts || [])
    );
    const primaryContact = getPrimaryContact(franchiseeData);
    if (
      !pocContacts.find(
        (contactItem) => contactItem.email === primaryContact.email
      )
    ) {
      pocContacts.unshift(primaryContact);
    }
    return pocContacts;
  }

  function renderVersion() {
    return (
      <div className="poc_block-action">
        {showDiff ? (
          <div
            className="poc_block-action-btn"
            onClick={() => setShowDiff(false)}
          >
            <EyeInvisibleOutlined />
          </div>
        ) : (
          <div
            className="poc_block-action-btn"
            onClick={() => setShowDiff(true)}
          >
            <EyeOutlined />
          </div>
        )}
        <Select
          defaultValue={"Please Select a Option"}
          style={{ width: 220 }}
          size="large"
          value={versionList?.length > 0 ? version : null}
          onChange={handleSwitchVersion}
        >
          {versionList.map((item, index) => (
            <Option value={index}>
              {`${item.updatedAt} - ${item.updatedBy}`}
            </Option>
          ))}
        </Select>
      </div>
    );
  }

  function renderEditBtn() {
    return (
      <div className="driver-title-extra">
        {viewMode === FORM_VIEW && (
          <Button
            type="default"
            size="large"
            icon={<EditOutlined />}
            onClick={() => setViewMode(FORM_EDIT)}
          >
            Edit
          </Button>
        )}
        {viewMode === FORM_EDIT && (
          <>
            <Button
              type="primary"
              icon={<SaveOutlined />}
              size="large"
              onClick={handleSaveForm}
            >
              Save
            </Button>
            <Button
              type="default"
              size="large"
              icon={<CloseOutlined />}
              onClick={() => setViewMode(FORM_VIEW)}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    );
  }

  async function handleSaveForm() {
    setLoading(true);
    try {
      const log_poc_contacts = franchiseeData?.log_poc_contacts || [];

      log_poc_contacts.push({
        updatedBy: user.email,
        updatedAt: new Date(),
        data: formVal
      });
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          poc_contacts: formVal,
          log_poc_contacts
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setFranchiseeData(result?.data?.entity);
      setViewMode(FORM_VIEW);
      notification.success({
        message: "Success",
        description: "POC Information is successfully updated!",
        duration: 5
      });
    } catch (error) {
      console.log("error ---", error);
      notification.error({
        message: "Failed",
        description:
          "Saving POC Information failed! Please contact admin directly.",
        duration: 5
      });
    }

    setLoading(false);
  }

  return (
    <Card
      className="poc_block"
      title="Point of Contact"
      extra={() => renderVersion()}
      titleExtra={() => renderEditBtn()}
    >
      <div className="poc_block-con">
        <BlockPOC
          data={formVal}
          onChange={setFormVal}
          disabled={viewMode === FORM_VIEW}
          franchiseeData={franchiseeData}
          setFranchiseeData={setFranchiseeData}
        />
      </div>
      {showDiff && (
        <div className="poc_block-diff">
          <div className="poc_block-diff-left">
            <label className="poc_block-diff-right-label">
              {version >= 0
                ? `${versionList[version].updatedAt} - ${versionList[version].updatedBy}`
                : "Current Version"}
            </label>

            <ReactJson
              src={formVal}
              theme="monokai"
              displayDataTypes={false}
              enableClipboard={false}
            />
          </div>
          <div className="poc_block-diff-right">
            <label className="poc_block-diff-right-label">
              Current Version
            </label>
            <ReactJson
              src={activeFormVal}
              theme="monokai"
              displayDataTypes={false}
              enableClipboard={false}
            />
          </div>
        </div>
      )}
      <Loading loading={loading} />
    </Card>
  );
}

import React from "react";
import { Card } from "../../../../components";
import { Table } from "antd";
import moment from "moment";
import {
  STATUS_EMAIL,
  STATUS_UPLOAD,
  STATUS_APPROVE,
  STATUS_APPROVE_LIABILITY,
  STATUS_APPROVE_AUTO,
  STATUS_APPROVE_WORKERS_COMPENSATION,
  STATUS_APPROVE_UMBRELLA,
  STATUS_APPROVE_ADDITIONAL_INFORMATION,
  STATUS_EMAIL_ISSUE,
  STATUS_EMAIL_EXPIRATION,
  STATUS_APPROVE_CRIME,
  STATUS_APPROVE_GARAGE,
  STATUS_APPROVE_EPLI,
  STATUS_APPROVE_CYBER,
  STATUS_APPROVE_PRO_LIAB,
  STATUS_APPROVE_POL_LIAB,
  STATUS_APPROVE_IM
} from "../../../../assets/const/status";
import { DOC_NAMING } from "@assets/const/ui";

export default function ActivityList(props) {
  const { franchiseeData } = props;
  const dataSourceActivity = (franchiseeData.activities || []).sort(
    (itemA, itemB) => {
      if (new Date(itemA.date) > new Date(itemB.date)) {
        return -1;
      } else {
        return 1;
      }
    }
  );

  function handleStatus(status, record) {
    switch (status) {
      case STATUS_EMAIL:
        return "Email was sent";
      case STATUS_UPLOAD:
        return `Franchisee uploaded document ${
          record?.note ? `(${DOC_NAMING[record.note]})` : ""
        }`;
      case STATUS_APPROVE:
        return "Documentation was approved";
      case STATUS_APPROVE_LIABILITY:
        return "Liability Documentation was approved";
      case STATUS_APPROVE_AUTO:
        return "Auto Document was approved";
      case STATUS_APPROVE_WORKERS_COMPENSATION:
        return "Workers Compensation Document was approved";
      case STATUS_APPROVE_UMBRELLA:
        return "Umbrella Document was approved";
      case STATUS_APPROVE_CRIME:
        return "Crime Document was approved";
      case STATUS_APPROVE_GARAGE:
        return "Garage Document was approved";
      case STATUS_APPROVE_EPLI:
        return "Employment Practices Liability was approved";
      case STATUS_APPROVE_CYBER:
        return "Cyber Liability was approved";
      case STATUS_APPROVE_PRO_LIAB:
        return "Professional Liability was approved";
      case STATUS_APPROVE_POL_LIAB:
        return "Pollution Liability was approved";
      case STATUS_APPROVE_IM:
        return "Inland Marine was approved";
      case STATUS_APPROVE_ADDITIONAL_INFORMATION:
        return "Additional Information was approved";
      case STATUS_EMAIL_ISSUE:
        return "Any Issue was detected in uploaded documentation";
      case STATUS_EMAIL_EXPIRATION:
        return "Document was expired";
      default:
        return status;
    }
  }

  const columnsActivity = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => <span>{moment(date).format("MM/DD/YYYY")}</span>
    },
    {
      title: "Activity",
      dataIndex: "action",
      key: "action",
      render: (status, record) => <span>{handleStatus(status, record)}</span>
    }
  ];

  return (
    <Card title="Activity">
      <Table dataSource={dataSourceActivity} columns={columnsActivity} />
    </Card>
  );
}

import React, { useState, useEffect, useMemo } from "react";
import "./style.scss";
import {
  Input,
  Loading,
  QuoteAction,
  QuoteHeader,
  QuoteProgressBar,
  QuoteStepper,
  Card,
} from "../../../components";
import { useAppState } from "../../../context";
import { AutoComplete, Checkbox, notification } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { get, put, post } from "../../../utils/axios";
import { Form, Alert } from "antd";
import { getAbbrev } from "../../../assets/const/location";
import ClassCodeInput from "../admin-appetite/class-code-input";
import BlockCode from "./block-code";
import { addMonths, format, isValid } from "date-fns";
import HartfordPreview from "./hartford-preview";
import BlockProfile from "./block-profile";
import LocationBlock from "../franchisee-detail/under-writing/location-block";
import OfficeBlock from "../franchisee-detail/under-writing/office-block";
import {
  COVERAGE_KEY_LIABILITY,
  COVERAGE_KEY_UMBRELLA,
  COVERAGE_KEY_WORKERS_COMP,
  COVERAGE_KEY_AUTO,
  COVERAGE_KEY_BOP,
} from "@assets/const/fim-integration";
import { DOC_MAX_LIMIT } from "@assets/const/status";
import { isEmpty, isUndefined } from "lodash";
import BopInforBlock from "../franchisee-detail/under-writing/bop-infor-block";
import { STATUS_SECTION_APPROVE } from "@assets/const/status";
import { getValidCode, validateSpecialInputFields } from "@utils/common";

const RIKOR_PRODUCER_CODE = "34471962";
const VIEW_MODE_FORM = "VIEW_MODE_FORM";
const VIEW_MODE_PREVIEW = "VIEW_MODE_PREVIEW";

function getPhontNum(phoneStr) {
  let phoneNum = phoneStr.match(/\d/g);
  phoneNum = phoneNum.join("");
  return phoneNum;
}

function filterEmptyValue(value) {
  if (value == "") {
    return undefined;
  }

  return value;
}

function convertStatus(status) {
  switch (status.toLowerCase()) {
    case "eligible":
      return "Bindable";
    case "ineligible":
      return "Not Yet Bindable";
    default:
      return status;
  }
}

// function args should match all quote functions
function prepareBaseQuoteParams({
  token,
  formCode,
  profileVal,
  franchiseeData,
  locationVal,
  officeVal,
}) {
  return {
    SICCode: filterEmptyValue(formCode.SICCode),
    NAICS: filterEmptyValue(formCode.NAICS),
    spectrumClassCode: filterEmptyValue(formCode.spectrumClassCode),
    sourceSystem: "Rikor Insurance Marketplace",
    sourceSystemType: filterEmptyValue(formCode.sourceSystemType),
    agentName: filterEmptyValue(formCode.agentName),
    legalEntityCd: filterEmptyValue(profileVal.legalEntityCd),
    producerCode: RIKOR_PRODUCER_CODE,
    commercialName: filterEmptyValue(profileVal.entity_name),
    doingBusinessAs: filterEmptyValue(profileVal.dba),
    communications: {
      contactName: filterEmptyValue(
        `${profileVal.first_name} ${profileVal.last_name}`
      ),
      phoneNumber: getPhontNum(profileVal.telephone),
      emailAddr: filterEmptyValue(profileVal.email),
    },
    mailingAddress: {
      addr1: filterEmptyValue(profileVal.address),
      city: filterEmptyValue(profileVal.city),
      stateProvCd: filterEmptyValue(getAbbrev(profileVal.state)),
      postalCode: filterEmptyValue(profileVal.zip),
    },
    FEIN: filterEmptyValue(profileVal.tax_id),
    effectiveDt: filterEmptyValue(
      format(new Date(profileVal.effective_date), "yyyy-MM-dd")
    ),
    businessStartYear: filterEmptyValue(
      format(new Date(profileVal.opening_date || "2019-01-01"), "yyyy")
    ),
    locations: (locationVal || []).map((item, itemIndex) => ({
      addr: {
        addr1: filterEmptyValue(item.address),
        city: filterEmptyValue(item.city),
        stateProvCd: filterEmptyValue(getAbbrev(item.state)),
        postalCode: filterEmptyValue(item.zip),
      },
    })),
  };
}

// This component is deprecated.
export default function AdminHartfordAppetite() {
  const history = useHistory();
  const location = useLocation();
  let { id } = useParams();
  const { user, getAccessTokenSilently } = useAuth0();
  const { codeList, setCodeList } = useAppState();
  const [franchiseeData, setFranchiseeData] = useState({});
  const [franchisorData, setFranchisorData] = useState({});
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(VIEW_MODE_FORM);

  const [profileVal, setProfileVal] = useState({
    first_name: "",
    last_name: "",
    email: "",
    telephone: "",
    rikor_id: "",
    entity_name: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    opening_date: "",
    effective_date: "",
    tax_id: "",
    dba: "",
    legalEntityCd: "",
  });
  const [locationVal, setLocationVal] = useState([]);
  const [officeVal, setOfficeVal] = useState([]);
  const [bopInfor, setBopInfor] = useState({});

  const [successResult, setSuccessResult] = useState();

  const [formCode, setFormCode] = useState({
    SICCode: "",
    NAICS: "",
    spectrumClassCode: "",
    sourceSystem: "",
    sourceSystemType: "",
    agentName: "",
    numLosses: "",
  });

  const [isBop, setIsBop] = useState(false);

  useEffect(() => {
    handleLoadData();
  }, []);

  useEffect(() => {
    const bopCoverage = (location?.state?.coverages || []).find(
      (item) => item === COVERAGE_KEY_LIABILITY || item === COVERAGE_KEY_BOP
    );
    if (bopCoverage) {
      setIsBop(true);
    }
  }, [location.state?.coverages]);

  async function handleLoadData() {
    try {
      setLoading(true);
      if (id) {
        await handleLoadFranchisee();
      }

      if (!codeList) {
        await handleLoadCodes();
      }
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  }

  async function handleSaveFranchisee() {
    try {
      const token = await getAccessTokenSilently();
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          locationList: locationVal,
          officeList: officeVal,
          bopInfor: bopInfor,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {}
  }

  async function handleSaveQuote() {
    try {
      const token = await getAccessTokenSilently();
      let quoteList = franchiseeData?.quoteList || [];
      setLoading(true);
      let requestData = prepareBaseQuoteParams({
        token,
        formCode,
        profileVal,
        franchiseeData,
        locationVal,
        officeVal,
      });

      // Add data required for BOP quotes
      // TODO: add fields for populating data below instead of hardcoding them
      requestData = {
        ...requestData,
        BOPTypeCode: "LiabOnly",
        // TODO: there are a _lot_ of optional fields that we don't include with
        // this quote. It would be a good idea to review what data we're sending
        // to make sure we get the most accurate quote for clients.
        constructionTypeCode: "F",
        includeBuildingCoverage: true,
        numEmployees: 3,
        numberOfOwners: 1,
        numberOfStories: 1,
        yearBuilt: 2020,
      };

      Object.entries(successResult).forEach((resultItem) => {
        quoteList.push({
          save_date: new Date().toISOString(),
          prepared_by: user.email,
          step: "",
          status: convertStatus(resultItem[1].streamlinedQuoteStatus),
          business_id: "", // GA Only,
          quote_id: resultItem[1].quoteIdentifier, // Hartford Only
          coverage: resultItem[0],
          response_detail: resultItem[1],
          request_detail: requestData,
          integration: "Hartford",
        });
      });
      const result = await put(
        `contact/${franchiseeData._id}`,
        {
          quoteList: quoteList,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
    } catch (error) {
      console.log("eror --", error);
      setLoading(false);
    }
  }

  function handleCheckValidation(infor) {
    let err = validateSpecialInputFields(profileVal);
    if (err) {
      handleNotification("Invalid Input", err);
      return false;
    }

    if (isEmpty(infor?.profileVal?.first_name)) {
      handleNotification("Missing Field", "First Name is required!");
      return false;
    }
    if (isEmpty(infor?.profileVal?.last_name)) {
      handleNotification("Missing Field", "Last Name is required!");
      return false;
    }
    if (isEmpty(infor?.profileVal?.email)) {
      handleNotification("Missing Field", "Email is required!");
      return false;
    }
    if (isEmpty(infor?.profileVal?.entity_name)) {
      handleNotification("Missing Field", "Entity Name is required!");
      return false;
    }
    if (isEmpty(infor?.profileVal?.legalEntityCd)) {
      handleNotification("Missing Field", "Legal Entity Code is required!");
      return false;
    }
    if (isEmpty(infor?.profileVal?.dba)) {
      handleNotification("Missing Field", "DBA is required!");
      return false;
    }
    if (isEmpty(infor?.profileVal?.address)) {
      handleNotification("Missing Field", "Address is required!");
      return false;
    }
    if (isEmpty(infor?.profileVal?.city)) {
      handleNotification("Missing Field", "City is required!");
      return false;
    }
    if (isEmpty(infor?.profileVal?.state)) {
      handleNotification("Missing Field", "State is required!");
      return false;
    }
    if (isEmpty(infor?.profileVal?.zip)) {
      handleNotification("Missing Field", "Zip is required!");
      return false;
    }
    if (isEmpty(infor?.profileVal?.telephone)) {
      handleNotification("Missing Field", "Telephone is required!");
      return false;
    }
    if (isEmpty(infor?.profileVal?.effective_date)) {
      handleNotification("Missing Field", "Effective Date is required!");
      return false;
    }
    if (!isValid(new Date(infor?.profileVal?.effective_date))) {
      handleNotification("Missing Field", "Effective Date is invalid!");
    }

    if (isEmpty(infor?.profileVal?.opening_date)) {
      handleNotification("Missing Field", "Opening Date is required!");
      return false;
    }

    if (!isValid(new Date(infor?.profileVal?.opening_date))) {
      handleNotification("Missing Field", "Opening Date is invalid!");
    }

    if (isEmpty(infor?.locationVal)) {
      handleNotification("Missing Field", "Location is Required");
      return false;
    }

    if (isEmpty(infor?.officeVal)) {
      handleNotification("Missing Field", "Office is Required");
      return false;
    }

    if (isEmpty(infor?.formCode?.spectrumClassCode)) {
      handleNotification("Missing Field", "Spectrum Class Code is Required");
      return false;
    }

    for (const locationItem of infor.locationVal) {
      if (isEmpty(locationItem.address)) {
        handleNotification("Missing Field", "Address is Required");
        return false;
      }
      if (isEmpty(locationItem.city)) {
        handleNotification("Missing Field", "City is Required");
        return false;
      }
      if (isEmpty(locationItem.state)) {
        handleNotification("Missing Field", "State is Required");
        return false;
      }
      if (isEmpty(locationItem.zip)) {
        handleNotification("Missing Field", "Zip is Required");
        return false;
      }
      if (isEmpty(locationItem.codeList)) {
        handleNotification("Missing Field", "Code is Required");
        return false;
      }

      for (const codeItem of locationItem.codeList) {
        if (isEmpty(codeItem.code)) {
          handleNotification("Missing Field", "Code is Required");
          return false;
        }
        if (isEmpty(codeItem.exposure)) {
          handleNotification("Missing Field", "exposure is Required");
          return false;
        }
        if (isEmpty(codeItem.employeeCount)) {
          handleNotification("Missing Field", "Employee Count is Required");
          return false;
        }
      }
    }

    for (const officeItem of infor.officeVal) {
      if (isEmpty(officeItem.full_name)) {
        handleNotification("Missing Field", "Full Name is Required");
        return false;
      }
      if (isEmpty(officeItem.payroll)) {
        handleNotification("Missing Field", "Payroll is Required");
        return false;
      }
      if (isEmpty(officeItem.class_code)) {
        handleNotification("Missing Field", "Class Code is Required");
        return false;
      }
      if (isEmpty(officeItem.owner_ship)) {
        handleNotification("Missing Field", "Ownership is Required");
        return false;
      }
    }

    return true;
  }

  async function handleWCCall(info) {
    try {
      let param = prepareBaseQuoteParams(info);

      // Add data required for WC quotes
      param = {
        ...param,
        locations: param.locations.map((item, idx) => {
          const original = info.locationVal[idx];
          return {
            ...item,
            workCompRateClassInfo: (original.codeList || []).map(
              (codeItem) => ({
                numEmployees: codeItem.employeeCount,
                ratingClassificationCd: getValidCode(codeItem.code),
                // ratingClassificationDescCd: "02", // optional
                exposure: codeItem.exposure,
              })
            ),
            owners: (info.officeVal || []).map((ownerItem) => ({
              ownerName: ownerItem.full_name || "",
              ownerPayroll: ownerItem.payroll || "",
              ratingClassificationCd: getValidCode(ownerItem.class_code),
              // ratingClassificationDescCd: "",
              ownerIncluded: !ownerItem.name_type,
              ownershipPercentage: ownerItem.owner_ship || "",
            })),
          };
        }),
      };

      const result = await post(`hartford/wc-quote`, param, {
        headers: {
          Authorization: `Bearer ${info.token}`,
        },
      });

      return result?.data?.data;
    } catch (error) {
      handleNotification("Failed", error.response?.data?.message);
      return null;
    }
  }

  async function handleBopCall(info, bopTypeCode) {
    try {
      let param = prepareBaseQuoteParams(info);

      // Add data required for BOP quotes
      // TODO: add fields for populating data below instead of hardcoding them
      param = {
        ...param,
        BOPTypeCode: bopTypeCode,
        // TODO: there are a _lot_ of optional fields that we don't include with
        // this quote. It would be a good idea to review what data we're sending
        // to make sure we get the most accurate quote for clients.
        numEmployees: 3,
        numberOfOwners: 1,
        totalArea: info?.bopInfor?.totalArea || "",
        yearBuilt: info?.bopInfor?.yearBuilt || 2020,
        constructionTypeCode: info?.bopInfor?.constructionTypeCode || "F",
        numberOfStories: info?.bopInfor?.numberOfStories || 1,
        includeBuildingCoverage: !isUndefined(
          info?.bopInfor?.includeBuildingCoverage
        )
          ? info?.bopInfor?.includeBuildingCoverage
          : true,
        bppLimit: info?.bopInfor?.bppLimit || "",
      };

      const result = await post(`hartford/bop-quote`, param, {
        headers: {
          Authorization: `Bearer ${info.token}`,
        },
      });

      return result?.data?.data;
    } catch (error) {
      handleNotification("Failed", error.response?.data?.message);
      return null;
    }
  }

  async function handleNext() {
    try {
      if (
        !handleCheckValidation({
          formCode,
          profileVal,
          franchiseeData,
          locationVal,
          officeVal,
          bopInfor,
        })
      ) {
        return;
      }

      setLoading(true);
      const token = await getAccessTokenSilently();
      const info = {
        token,
        formCode,
        profileVal,
        franchiseeData,
        locationVal,
        officeVal,
        bopInfor,
      };
      await handleSaveFranchisee();
      // TODO: make parallel queries for all quotes
      const coverageList = location.state?.coverages || [];

      let resultData = {};
      // Bop Integration Section
      const coverageBop =
        coverageList.filter(
          (item) => item === COVERAGE_KEY_LIABILITY || item === COVERAGE_KEY_BOP
        ) || [];
      if (coverageBop.length > 0 && isBop) {
        let bopTypeCode = "";
        if (coverageBop.length === 2) {
          bopTypeCode = "PropLiab";
        } else if (coverageBop[0] === COVERAGE_KEY_LIABILITY) {
          bopTypeCode = "LiabOnly";
        } else {
          bopTypeCode = "PropOnly";
        }

        resultData[COVERAGE_KEY_LIABILITY] = await handleBopCall(
          info,
          bopTypeCode
        );
      }

      // WC Integration Section
      const coverageWC =
        coverageList.filter((item) => item === COVERAGE_KEY_WORKERS_COMP) || [];
      if (coverageWC.length > 0) {
        resultData[COVERAGE_KEY_WORKERS_COMP] = await handleWCCall(info);
      }
      setSuccessResult(resultData);
      setViewMode(VIEW_MODE_PREVIEW);
      handleNotification("Success", "Quote created!", "success");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error.response?.data?.message);
      handleNotification("Failed", error.response?.data?.message);
    }
  }

  function handleNotification(title, description, type) {
    if (type === "success") {
      notification.success({
        message: title,
        description: description,
        duration: 5,
      });
    } else {
      notification.error({
        message: title,
        description: description,
        duration: 5,
        placement: "bottomRight",
      });
    }
  }

  async function handleLoadCodes() {
    try {
      const token = await getAccessTokenSilently();
      const result = await get("admin/code", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const codeListData = result?.data?.data || [];
      setCodeList(codeListData);
    } catch (error) {
      console.log("nacis code load error ---", error);
    }
  }

  async function handleLoadFranchisee() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const franchiseeData = result.data.data;
      setFranchiseeData(franchiseeData);

      if (franchiseeData?.isInsureDiff) {
        setProfileVal({
          ...profileVal,
          ...franchiseeData.insuranceData,
          legalEntityCd: "CP",
        });
      } else {
        setProfileVal({
          ...profileVal,
          first_name: franchiseeData?.first_name,
          last_name: franchiseeData?.last_name,
          email: franchiseeData?.email,
          telephone: franchiseeData?.telephone,
          rikor_id: franchiseeData?._id,
          entity_name: franchiseeData.entity_name,
          address: franchiseeData.address,
          city: franchiseeData.city,
          state: franchiseeData.state,
          zip: franchiseeData.zip,
          opening_date: franchiseeData?.detail?.openingDate,
          tax_id: franchiseeData?.detail?.fimEntity?.fimTtTaxpayer,
          legalEntityCd: "CP",
        });
      }

      setLocationVal(franchiseeData?.locationList || []);

      setOfficeVal(franchiseeData?.officeList || []);

      setBopInfor(franchiseeData?.bopInfor || []);

      setBopInfor(franchiseeData?.bopInfor || {});

      const resultRequirement = await get(
        `requirement/${result.data.data.requirementId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const franchisorData = resultRequirement.data.data;
      setFranchisorData(franchisorData);

      const formCode = {
        SICCode: (franchisorData?.naics_classes || []).find(
          (item) => item.eleType === "SIC"
        )?.code,
        NAICS: (franchisorData?.naics_classes || []).find(
          (item) => item.eleType === "NAICS"
        )?.code,
        spectrumClassCode: "",
        sourceSystem: "Rikor Insurance Marketplace",
        sourceSystemType: "",
        agentName: "",
      };

      setFormCode(formCode);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  }

  function handleBack() {
    history.push(`/admin/franchisees/${id}/insurance-quote`);
  }

  function handleClose() {
    history.push(`/admin/franchisees/${id}`, { ...location?.state });
  }

  function handleSave() {}

  function handleChangeCode(key, value) {
    setFormCode({
      ...formCode,
      [key]: value,
    });
  }

  function handleChangeProfile(key, value) {
    setProfileVal({
      ...profileVal,
      [key]: value,
    });
  }

  async function handleAccept() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const finalData = franchiseeData?.finalData || {};
      const result = await put(
        `contact/${id}`,
        {
          finalData: {
            ...finalData,
            commercial_general_liability_form_status: STATUS_SECTION_APPROVE,
            commercial_general_liability_form_doc_index: DOC_MAX_LIMIT,
            commercial_general_liability_each_occurance: (
              successResult[COVERAGE_KEY_LIABILITY]?.coverage || []
            )
              .find((item) => item.description === "Business Liability Limit")
              ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
              .limitAmount,
            commercial_general_liability_damage_rented_premises: (
              successResult[COVERAGE_KEY_LIABILITY]?.coverage || []
            )
              .find(
                (item) =>
                  item.description === "Damage to Premises Rented to You"
              )
              ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
              .limitAmount,
            commercial_general_liability_medical_expenses: (
              successResult[COVERAGE_KEY_LIABILITY]?.coverage || []
            )
              .find((item) => item.description === "Medical Expenses")
              ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
              .limitAmount,
            commercial_general_liability_personal_adv_injury: (
              successResult[COVERAGE_KEY_LIABILITY]?.coverage || []
            )
              .find(
                (item) =>
                  item.description ===
                  "Personal and Advertising Injury Coverage Limit"
              )
              ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
              .limitAmount,
            commercial_general_liability_general_aggregate: (
              successResult[COVERAGE_KEY_LIABILITY]?.coverage || []
            )
              .find((item) => item.description === "General Aggregate Limit")
              ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
              .limitAmount,
            commercial_general_liability_comprop_acg: (
              successResult[COVERAGE_KEY_LIABILITY]?.coverage || []
            )
              .find(
                (item) =>
                  item.description ===
                  "Products/Completed Operations Aggregate Limit"
              )
              ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
              .limitAmount,
            commercial_general_liability_policy_number:
              successResult[COVERAGE_KEY_LIABILITY]?.policyNumber,
            commercial_general_liability_additional_insurance:
              (successResult[COVERAGE_KEY_LIABILITY]?.coverage || [])
                .find(
                  (item) =>
                    item.description === "Additional Insured by Contract"
                )
                ?.limit.reduce((result, item) => ({ ...result, ...item }), {})
                .limitAppliestoCode === "Included",
            commercial_general_liability_effective_date: format(
              new Date(),
              "yyyy-MM-dd"
            ),
            commercial_general_liability_expire_date: format(
              addMonths(new Date(), 12),
              "yyyy-MM-dd"
            ),
            workers_compensation_form_status: STATUS_SECTION_APPROVE,
            workers_compensation_form_doc_index: DOC_MAX_LIMIT,
            workers_compensation_policy_number:
              successResult[COVERAGE_KEY_WORKERS_COMP]?.policyNumber,
            workers_compensation_el_each_accident:
              (successResult[COVERAGE_KEY_WORKERS_COMP]?.coverage || [])
                .length > 0
                ? (
                    (successResult[COVERAGE_KEY_WORKERS_COMP]?.coverage ||
                      [])[0].limit || []
                  ).find((item) => item.limitAppliestoCode === "PerAcc")
                    ?.limitAmount
                : "",
            workers_compensation_el_disease_policy_limit:
              (successResult[COVERAGE_KEY_WORKERS_COMP]?.coverage || [])
                .length > 0
                ? (
                    (successResult[COVERAGE_KEY_WORKERS_COMP]?.coverage ||
                      [])[0].limit || []
                  ).find((item) => item.limitAppliestoCode === "DisPol")
                    ?.limitAmount
                : "",
            workers_compensation_other_el_disease_each_employer:
              (successResult[COVERAGE_KEY_WORKERS_COMP]?.coverage || [])
                .length > 0
                ? (
                    (successResult[COVERAGE_KEY_WORKERS_COMP]?.coverage ||
                      [])[0].limit || []
                  ).find((item) => item.limitAppliestoCode === "DisEachEmpl")
                    ?.limitAmount
                : "",
            workers_compensation_effective_date: format(
              new Date(),
              "yyyy-MM-dd"
            ),
            workers_compensation_expire_date: format(
              addMonths(new Date(), 12),
              "yyyy-MM-dd"
            ),
            // all five must be approved in order to proceed to a compliant
            // status, but server side we unset the following three since we're
            // not handling any quotes for them right now.
            automotive_liability_form_status: STATUS_SECTION_APPROVE,
            automotive_liability_form_doc_index: DOC_MAX_LIMIT,
            umbrella_subrogration_form_status: STATUS_SECTION_APPROVE,
            umbrella_subrogration_form_doc_index: DOC_MAX_LIMIT,
            additional_infor_form_status: STATUS_SECTION_APPROVE,
            additional_infor_doc_index: DOC_MAX_LIMIT,

            // Provide coverage details
          },
          liabilityIssue: [],
          autoIssue: [],
          umbrellaIssue: [],
          workCompIssue: [],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      history.push(`/admin/franchisees/${id}`);
    } catch (err) {
      setLoading(false);
    }
  }

  return (
    <div className="admin_hartford">
      <QuoteProgressBar step={1}></QuoteProgressBar>
      <QuoteHeader
        franchiseeData={franchiseeData}
        requirement={franchisorData}
        user={user}
      ></QuoteHeader>
      <div className="admin_hartford-container">
        <QuoteStepper step={1}></QuoteStepper>
        <div className="right-content">
          {viewMode === VIEW_MODE_FORM && (
            <>
              <div className="admin_hartford-container-wrapper">
                <BlockProfile data={profileVal} onChange={setProfileVal} />

                <Card title="Locations">
                  <LocationBlock
                    data={locationVal}
                    onChange={setLocationVal}
                    coverages={location.state?.coverages}
                  />
                </Card>
                <Card title="Offices">
                  <OfficeBlock data={officeVal} onChange={setOfficeVal} />
                </Card>
                <Card
                  title="Property Information"
                  extra={() => (
                    <div className="insured_information-action">
                      <Checkbox
                        checked={isBop}
                        onChange={(evt) => setIsBop(evt.target.checked)}
                      >
                        Property
                      </Checkbox>
                    </div>
                  )}
                >
                  {isBop && (
                    <BopInforBlock data={bopInfor} onChange={setBopInfor} />
                  )}
                </Card>

                <BlockCode
                  formVal={formCode}
                  codeData={franchisorData.naics_classes || []}
                  onChange={handleChangeCode}
                />
              </div>
              <QuoteAction
                handleClose={handleClose}
                handleSave={handleSave}
                handleBack={handleBack}
                handleNext={handleNext}
              ></QuoteAction>
            </>
          )}
          {viewMode === VIEW_MODE_PREVIEW && (
            <>
              <HartfordPreview {...successResult} />
              <QuoteAction
                handleAccept={handleAccept}
                handleClose={handleClose}
                handleSave={handleSaveQuote}
                visibleSave={viewMode === VIEW_MODE_PREVIEW}
                visibleBack={false}
                visibleNext={false}
              ></QuoteAction>
            </>
          )}
        </div>
      </div>

      <Loading loading={loading} />
    </div>
  );
}

import Input from "@components/input";
import { Radio, Select, Switch } from "antd";
import _ from "lodash";
import "./style.scss";

export default function CustomQuestionsSection({
  show,
  section,
  questions,
  onChange,
  onShowToggle,
}) {
  function handleQuestionAnswer(qIndex, answer) {
    const questionsCopy = _.cloneDeep(questions);
    questionsCopy[qIndex].value = answer;
    onChange(questionsCopy);
  }

  return (
    <div className="show-form-toggle-container">
      <h4>{section}</h4>
      <Radio.Group value={show || false} onChange={onShowToggle}>
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group>
      {show && (
        <div className="form-container">
          <div className="custom-questions-container">
            {questions.map((q, qIndex) => (
              <div className="custom-question">
                <h4>{q.title}</h4>
                {q.eleType === "boolean" && (
                  <Switch
                    checked={q.value}
                    onChange={(checked) =>
                      handleQuestionAnswer(qIndex, checked)
                    }
                  />
                )}
                {q.eleType === "string" && (
                  <Input
                    size="large"
                    value={q.value}
                    onChange={(evt) =>
                      handleQuestionAnswer(qIndex, evt.target.value)
                    }
                  />
                )}
                {q.eleType === "number" && (
                  <Input
                    size="large"
                    value={q.value}
                    onChange={(evt) =>
                      handleQuestionAnswer(qIndex, evt.target.value)
                    }
                  />
                )}
                {q.eleType === "select" && (
                  <Select
                    size="large"
                    style={{ width: "500px" }}
                    onChange={(value) => {
                      handleQuestionAnswer(qIndex, value);
                    }}
                    value={q.value}
                    options={q.options || []}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

import BuildOutForm from "@components/forms/build-out";
import { Radio } from "antd";

export default function BuildOutSection({ form, show, onShowToggle }) {
  return (
    <div className="show-form-toggle-container">
      <h4>Do you have a build-out?</h4>
      <Radio.Group value={show || false} onChange={onShowToggle}>
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group>

      {show && (
        <div className="form-container">
          <BuildOutForm
            form={form}
            disableValidation={!show}
            rhfKey="buildOutFormVal"
          />
        </div>
      )}
    </div>
  );
}

import { COVERAGE_KEY_PROPERTY } from "@assets/const/fim-integration";
import LocationBlock from "../../../admin-board/franchisee-detail/under-writing/location-block";
import { Radio } from "antd";

export default function PropertySection({
  show,
  onShowToggle,
  franchiseeData,
  formVal,
  onChange,
}) {
  return (
    <div className="show-form-toggle-container">
      <h4>
        Do you need to insure equipment, stock, inventory, furniture at a
        physical location?
      </h4>
      <Radio.Group value={show || false} onChange={onShowToggle}>
        <Radio.Button value={true}>Yes</Radio.Button>
        <Radio.Button value={false}>No</Radio.Button>
      </Radio.Group>

      {show && (
        <div className="form-container">
          <LocationBlock
            franchiseeData={franchiseeData}
            coverageType={COVERAGE_KEY_PROPERTY}
            data={formVal}
            onChange={onChange}
          />
          <a
            className="admin_franchisee_under-section-footer-link"
            onClick={() => {
              onChange((prev) => [
                ...prev,
                {
                  address: formVal.address,
                  city: formVal.city,
                  state: formVal.state,
                  zip: formVal.zip,
                  propInfo: {},
                },
              ]);
            }}
          >
            Add your Mailing/Billing address as a location
          </a>
        </div>
      )}
    </div>
  );
}

import _ from "lodash";
import "./style.scss";
import { useFieldArray } from "react-hook-form";
import InputText from "@components/input/text";
import InputShowHide from "@components/input/show-hide";
import InputMask from "@components/input/mask";
import { CarOutlined, DeleteTwoTone } from "@ant-design/icons";
import { Button } from "antd";
import { z } from "zod";
import { requiredZodErr } from "@utils/common";
import InputSelect from "@components/input/select";
import InputAddress from "@components/input/address";

const SELECT_OPTIONS = [
  {
    value: true,
    label: "Yes"
  },
  {
    value: false,
    label: "No"
  }
];

export default function VehicleForm({
  disableValidation,
  rhfKey,
  form: {
    register,
    control,
    formState: { errors },
    trigger,
    setValue
  }
}) {
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "vehicleFormVal" // unique name for your Field Array
  });

  function renderVehicleInfo(field, vehicleIndex) {
    return (
      <div key={field.id} className="vehicle-info-container">
        <div className="form-group-four-container">
          <InputMask
            title="Year"
            mask="9999"
            rhfKey={`${rhfKey}.${vehicleIndex}.year`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.[vehicleIndex]?.year?.message}
          />
          <InputText
            title="Make"
            rhfKey={`${rhfKey}.${vehicleIndex}.make`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.[vehicleIndex]?.make?.message}
          />
          <InputText
            title="Model"
            rhfKey={`${rhfKey}.${vehicleIndex}.model`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.[vehicleIndex]?.model?.message}
          />
          <InputText
            title="Vehicle Value"
            rhfKey={`${rhfKey}.${vehicleIndex}.value`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.[vehicleIndex]?.value?.message}
          />
        </div>
        <div className="form-group-four-container">
          <InputText
            title="Attached Equipment Value"
            rhfKey={`${rhfKey}.${vehicleIndex}.equipment`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.[vehicleIndex]?.equipment?.message}
          />
          <InputMask
            title="VIN"
            mask={"$".repeat(17)}
            formatChars={{
              $: "[A-HJ-NPR-Z0-9]"
            }}
            rhfKey={`${rhfKey}.${vehicleIndex}.vin`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.[vehicleIndex]?.vin?.message}
          />
          <InputSelect
            title="Parked at Home?"
            control={control}
            options={SELECT_OPTIONS}
            rhfKey={`${rhfKey}.${vehicleIndex}.parkHome`}
            error={errors[rhfKey]?.parkHome?.message}
          />
          <InputSelect
            title="Tracking device installed?"
            control={control}
            options={SELECT_OPTIONS}
            rhfKey={`${rhfKey}.${vehicleIndex}.trackDevice`}
            error={errors[rhfKey]?.trackDevice?.message}
          />
        </div>
        <DeleteTwoTone
          className="delete-button"
          twoToneColor="#f5222d"
          onClick={() => {
            remove(vehicleIndex);
          }}
        />
        <InputAddress
          title="Garage Address"
          control={control}
          rhfKey={`${rhfKey}.${vehicleIndex}.garageAddress`}
          onPlaceSelected={({ zip, city, state, address }) => {
            setValue(`${rhfKey}.${vehicleIndex}.garageAddress`, address, {
              shouldValidate: true
            });
            setValue(`${rhfKey}.${vehicleIndex}.garageZip`, zip, {
              shouldValidate: true
            });
            setValue(`${rhfKey}.${vehicleIndex}.garageCity`, city, {
              shouldValidate: true
            });
            setValue(`${rhfKey}.${vehicleIndex}.garageState`, state, {
              shouldValidate: true
            });
          }}
        />
        <div className="form-group-three-container">
          <InputText
            title="City"
            rhfKey={`${rhfKey}.${vehicleIndex}.garageCity`}
            register={register}
          />
          <InputText
            title="State"
            rhfKey={`${rhfKey}.${vehicleIndex}.garageState`}
            register={register}
          />
          <InputMask
            title="Zip"
            mask="99999"
            rhfKey={`${rhfKey}.${vehicleIndex}.garageZip`}
            register={register}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      {fields.map((field, index) => renderVehicleInfo(field, index))}
      <div className="add-button-container">
        <Button
          type="primary"
          onClick={() => {
            const newVehicle = {
              year: "",
              make: "",
              model: "",
              vin: ""
            };
            append(newVehicle);
            trigger(
              Object.keys(newVehicle).map(
                (k) => `${rhfKey}.${fields.length}.${k}`
              )
            );
          }}
        >
          <CarOutlined />
          Add Vehicle
        </Button>
      </div>
    </div>
  );
}

export const defaultSchema = z.array(
  z.object({
    year: z
      .string(requiredZodErr("Vehicle year"))
      .nonempty(requiredZodErr("Vehicle year")),
    make: z
      .string(requiredZodErr("Vehicle make"))
      .nonempty(requiredZodErr("Vehicle make")),
    model: z
      .string(requiredZodErr("Vehicle model"))
      .nonempty(requiredZodErr("Vehicle model")),
    vin: z
      .string(requiredZodErr("VIN number"))
      .nonempty(requiredZodErr("VIN number"))
      .regex(
        /^[A-HJ-NPR-Z0-9]{17}$/,
        "VIN number must be 17 - all cap - alphanumeric characters (except I, O, and Q)."
      )
  })
);

import { Button, Form, Input, Radio, Select, Switch } from "antd";
import React from "react";
import "./style.scss";
import { PREFERRED_CONTACT_METHOD_LIST, ROLE_LIST } from "@assets/const/ui";

export default function ZorPOCForm({ form, onSubmit }) {
  function validatePassword(rule, value, callback) {
    if (!value.match(`(?=.*[a-z])`)) {
      return callback("Need to add lower case");
    }
    if (!value.match(`(?=.*[A-Z])`)) {
      return callback("Need to add upper case");
    }
    if (!value.match(`(?=.*[0-9])`)) {
      return callback("Need to add number");
    }

    if (!value.match(`(?=.*[!@#$&*])`)) {
      return callback("Need to add Special characters");
    }

    if (value.length < 8) {
      return callback("Need to at least 8 letters");
    }

    callback();
  }

  return (
    <Form form={form} onFinish={onSubmit}>
      <div className="form-group-two-container">
        <div>
          <label className="input-title">First Name</label>
          <Form.Item
            name="firstName"
            rules={[
              { required: true, message: "Please input your first name." }
            ]}
          >
            <Input placeholder="First Name" size="large" />
          </Form.Item>
        </div>
        <div>
          <label className="input-title">Last Name</label>
          <Form.Item
            name="lastName"
            rules={[
              { required: true, message: "Please input your last name." }
            ]}
          >
            <Input placeholder="Last Name" size="large" />
          </Form.Item>
        </div>
      </div>
      <div className="form-group-two-container">
        <div>
          <label className="input-title">Email</label>
          <Form.Item
            name="email"
            rules={[
              { type: "email", message: "Please input valid email." },
              { required: true, message: "Please input your email." }
            ]}
          >
            <Input placeholder="Email" type="email" size="large" />
          </Form.Item>
        </div>
        <div>
          <label className="input-title">Phone</label>
          <Form.Item
            name="telephone"
            rules={[
              { required: true, message: "Please input your telephone." }
            ]}
          >
            <Input placeholder="Telephone" size="large" />
          </Form.Item>
        </div>
      </div>
      <div className="form-group-two-container">
        <div>
          <label className="input-title">Direct Line</label>
          <Form.Item name="directLine" rules={[]}>
            <Input placeholder="Direct Line" size="large" />
          </Form.Item>
        </div>

        <div>
          <label className="input-title">Preferred Contact Method</label>
          <Form.Item name="preferredMethod">
            <Radio.Group>
              {PREFERRED_CONTACT_METHOD_LIST.map((item) => (
                <Radio value={item.value}>{item.label}</Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
      <div className="form-group-two-container">
        <div>
          <label className="input-title">Role</label>
          <Form.Item name="role">
            <Select
              style={{ width: "100%" }}
              size="large"
              options={ROLE_LIST}
            />
          </Form.Item>
        </div>
        <div className="form-group-two-container">
          <div className="custom-input-container">
            <label className="input-title">Primary</label>
            <Form.Item name="isPrimary">
              <Switch />
            </Form.Item>
          </div>
          <div className="custom-input-container">
            <label className="input-title">Active</label>
            <Form.Item name="isActive">
              <Switch />
            </Form.Item>
          </div>
        </div>
      </div>

      <div>
        <label className="input-title">Password</label>

        <Form.Item
          name="password"
          hasFeedback
          rules={[
            { required: true, message: "Please input password." },
            { validator: validatePassword }
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
      </div>
      <div>
        <label className="input-title">Password Repeat</label>

        <Form.Item
          name="passwordRepeat"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!"
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              }
            })
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
      </div>
    </Form>
  );
}

import { useState, useEffect, useMemo } from "react";
import "./style.scss";
import {
  Input,
  Loading,
  QuoteAction,
  QuoteHeader,
  QuoteProgressBar,
  QuoteStepper,
  Card,
} from "../../../components";
import { useAppState } from "../../../context";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { get, post, put } from "../../../utils/axios";
import HartfordPreview from "../admin-insurance-quote-preview/components/hartford-preview";
import LocationBlock from "../franchisee-detail/under-writing/location-block";
import OfficeBlock from "../franchisee-detail/under-writing/office-block";
import {
  COVERAGE_KEY_LIABILITY,
  COVERAGE_KEY_WORKERS_COMP,
  COVERAGE_KEY_PROPERTY,
  COVERAGES,
  INSURANCE_CARRIERS,
  INSURANCE_CARRIER_KEY_HARTFORD,
  INSURANCE_CARRIER_KEY_GA,
  INSURANCE_CARRIER_KEY_AMTRUST,
  QUOTE_PROPOSAL_SOURCE_API,
  NUM_STEPS_TO_QUOTE,
} from "../../../assets/const/fim-integration";
import FormEffectiveExpireDates from "./components/form-effective-expire-dates";
import { useHartford } from "./hooks/useHartford";
import { useFormValidate } from "./hooks/useFormValidate";
import { CoverageCarrierSelection } from "./components/coverage-carrier-selection";
import _ from "lodash";
import FormProfile from "./components/form-profile";
import { useGreatAmerican } from "./hooks/useGreatAmerican";
import { Alert } from "antd";
import ClassCodeInput from "../admin-appetite/class-code-input";
import { notify, toUSD } from "../../../utils/common";
import { useAmTrust } from "./hooks/useAmTrust";
import AmTrustQuoteQuestions from "../admin-insurance-quote-questions/components/amtrust-questions";
import { flushSync } from "react-dom";
import AdminInsuranceQuoteProposal from "../admin-insurance-quote-proposal";
import InputSelect from "@components/input/select";

const VIEW_MODE_FORM = "VIEW_MODE_FORM";
const VIEW_MODE_QUESTIONS = "VIEW_MODE_QUESTIONS";
const VIEW_MODE_PREVIEW = "VIEW_MODE_PREVIEW";

export default function AdminInsuranceQuote() {
  const history = useHistory();
  const location = useLocation();
  const { validate: baseValidate } = useFormValidate();
  let { id } = useParams();
  const { user, getAccessTokenSilently } = useAuth0();
  const { codeList, setCodeList } = useAppState();

  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(VIEW_MODE_FORM);
  const [currentStep, setCurrentStep] = useState(1);

  // State for coverage and carrier selection.
  const [selectedCoverages, setSelectedCoverages] = useState({});
  const [selectedCarrier, setSelectedCarrier] = useState(
    () =>
      Object.keys(INSURANCE_CARRIERS).filter(
        (key) => INSURANCE_CARRIERS[key].default
      )[0] ?? ""
  );

  // State for coverage form values required for submitting quotes.
  const [franchiseeData, setFranchiseeData] = useState({});
  const [franchisorData, setFranchisorData] = useState({});
  const [profileVal, setProfileVal] = useState({
    first_name: "",
    last_name: "",
    email: "",
    telephone: "",
    rikor_id: "",
    entity_name: "",
    entity_type: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    opening_date: "",
    effective_date: "",
    tax_id: "",
    dba: "",
  });
  const [locationVal, setLocationVal] = useState([]);
  const [officeVal, setOfficeVal] = useState([]);
  const [bopInfor, setBopInfor] = useState({});
  const [liabInfo, setLiabInfo] = useState({});
  const [effectiveDate, setEffectiveDate] = useState({}); // coverage type -> date string (YYYY-MM-DD)
  const [expirationDate, setExpirationDate] = useState({}); // coverage type -> date string (YYYY-MM-DD)
  const [formCode, setFormCode] = useState({
    SICCode: "",
    NAICS: "",
    spectrumClassCode: "",
    sourceSystem: "",
    sourceSystemType: "",
    agentName: "",
    numLosses: "",
  });
  const [nacisCode, setNacisCode] = useState({
    code: null,
    description: null,
  });
  const [employeeLiability, setEmployeeLiability] = useState({});
  const [underwritingInfo, setUnderwritingInfo] = useState({});

  const franchisorNaicsCode = useMemo(() => {
    if (franchisorData?.naics_classes) {
      const nacisCode = franchisorData?.naics_classes.find(
        (item) => item.eleType === "NAICS"
      );
      return nacisCode;
    } else {
      return null;
    }
  }, [franchisorData]);
  const greatAmericanUsable = useMemo(() => {
    return nacisCode?.code || franchisorNaicsCode?.code;
  }, [nacisCode, franchisorNaicsCode]);

  // Hooks for insurance carriers.
  const {
    acceptQuote: acceptHartfordQuote,
    requestQuote: requestHartfordQuote,
    saveQuote: saveHartfordQuote,
    validate: validateHartford,
    constructWCQuoteProposal: constructHFWCQuoteProposal,
  } = useHartford();
  const {
    requestQuote: requestAmTrustQuote,
    saveQuote: saveAmTrustQuote,
    constructWCQuoteProposal: constructAmTrustWCQuoteProposal,
  } = useAmTrust({
    franchiseeData,
    franchisorData,
  });
  const { getAppId } = useGreatAmerican({ franchiseeData, franchisorData });

  // State for quote result.
  const [quoteResult, setQuoteResult] = useState();

  // State for quote questions.
  const [quoteQuestions, setQuoteQuestions] = useState([]);

  useEffect(() => {
    handleLoadData();
  }, []);

  function isFormValid() {
    if (selectedCarrier === INSURANCE_CARRIER_KEY_HARTFORD) {
      return validateHartford(getFormData(), selectedCoverages);
    }

    return baseValidate(getFormData(), selectedCoverages);
  }

  function getFormData() {
    return {
      formCode,
      profileVal,
      franchiseeData,
      franchisorData,
      locationVal,
      officeVal,
      bopInfor,
      liabInfo,
      effectiveDate,
      expirationDate,
      employeeLiability,
      underwritingInfo,
    };
  }

  function handleClose() {
    history.push(`/admin/franchisees/${id}`, { ...location?.state });
  }

  function handleChangeNacis(key, value) {
    setNacisCode({
      ...nacisCode,
      [key]: value,
    });
  }

  function handleSelectNacis(key, value) {
    const codeData = value.split(" - ");
    setNacisCode({
      ...profileVal,
      code: codeData[0],
      description: codeData[1],
    });
  }

  async function handleLoadData() {
    try {
      setLoading(true);
      if (id) {
        await handleLoadFranchisee();
      }

      if (!codeList) {
        await handleLoadCodes();
      }
      setLoading(false);
    } catch (error) {
      setLoading(true);
    }
  }

  async function handleLoadFranchisee() {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const result = await get(`contact/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const franchiseeData = result.data.data;
      setFranchiseeData(franchiseeData);

      const resultRequirement = await get(
        `requirement/${result.data.data.requirementId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const franchisorData = resultRequirement.data.data;
      setFranchisorData(franchisorData);

      if (franchiseeData?.isInsureDiff) {
        setProfileVal({
          ...profileVal,
          ...franchiseeData.insuranceData,
          entity_type: franchiseeData.entity_type ?? "CP",
        });
      } else {
        setProfileVal({
          ...profileVal,
          first_name: franchiseeData?.first_name,
          last_name: franchiseeData?.last_name,
          email: franchiseeData?.email,
          telephone: franchiseeData?.telephone,
          rikor_id: franchiseeData?._id,
          entity_name: franchiseeData?.entity_name,
          entity_type: franchiseeData?.entity_type ?? "CP",
          address: franchiseeData?.address,
          city: franchiseeData?.city,
          state: franchiseeData?.state,
          zip: franchiseeData?.zip,
          opening_date:
            franchiseeData?.opening_date || franchiseeData?.detail?.openingDate,
          tax_id:
            franchiseeData?.tax_id ||
            franchiseeData?.detail?.fimEntity?.fimTtTaxpayer,
          dba: franchiseeData?.dba,
        });
      }

      setLocationVal(franchiseeData?.locationList || []);
      setOfficeVal(franchiseeData?.officeList || []);
      setBopInfor(franchiseeData?.bopInfor || {});
      setLiabInfo(franchiseeData?.liabInfo || {});
      const formCode = {
        SICCode: (franchisorData?.naics_classes || []).find(
          (item) => item.eleType === "SIC"
        )?.code,
        NAICS: (franchisorData?.naics_classes || []).find(
          (item) => item.eleType === "NAICS"
        )?.code,
        spectrumClassCode: "",
        sourceSystem: "Rikor Insurance Marketplace",
        sourceSystemType: "",
        agentName: "",
      };
      console.log("franchisor naics classes", franchisorData?.naics_classes);
      setFormCode(formCode);
      setEmployeeLiability(franchiseeData?.employeeLiability || {});
      setUnderwritingInfo(franchiseeData?.underwritingInformation || {});
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      notify("Error", "Failed to load franchisee data", "error");
    } finally {
      setLoading(false);
    }
  }

  async function handleLoadCodes() {
    try {
      const token = await getAccessTokenSilently();
      const result = await get("admin/code", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const codeListData = result?.data?.data || [];
      console.log("Code list ", codeListData);
      setCodeList(codeListData);
    } catch (error) {
      console.log("naics code load error ---", error);
    }
  }

  async function handleSaveFranchisee() {
    try {
      const token = await getAccessTokenSilently();
      await put(
        `contact/${franchiseeData._id}`,
        {
          ...(franchiseeData.isInsureDiff
            ? {
                insuranceData: profileVal,
              }
            : {}),
          locationList: locationVal,
          officeList: officeVal,
          bopInfor: bopInfor,
          liabInfo: liabInfo,
          employeeLiability: employeeLiability,
          underwritingInformation: underwritingInfo,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.log("error", error);
      notify("Error", "Failed to save franchisee data", "error");
    }
  }

  async function handleSaveQuote() {
    try {
      if (!isFormValid()) {
        return;
      }

      setLoading(true);
      switch (selectedCarrier) {
        case INSURANCE_CARRIER_KEY_HARTFORD:
          await saveHartfordQuote(getFormData(), quoteResult);
          break;
        case INSURANCE_CARRIER_KEY_AMTRUST:
          await saveAmTrustQuote(getFormData(), {
            [COVERAGE_KEY_WORKERS_COMP]: quoteResult,
          });
        default:
          break;
      }
      notify("Success", "Successfully saved quote", "success");
      setLoading(false);
    } catch (error) {
      console.log("error --", error);
      notify("Error", "Failed to save quote", "error");
    } finally {
      setLoading(false);
    }
  }

  async function handleNext() {
    try {
      if (!isFormValid()) {
        return;
      }

      setLoading(true);
      await handleSaveFranchisee();
      switch (selectedCarrier) {
        case INSURANCE_CARRIER_KEY_HARTFORD:
          setQuoteResult(
            await requestHartfordQuote(
              getFormData(),
              franchiseeData,
              selectedCoverages
            )
          );
          setViewMode(VIEW_MODE_PREVIEW);
          notify("Success", "Hartford quote created!", "success", "bottomLeft");
          break;
        case INSURANCE_CARRIER_KEY_AMTRUST:
          const quoteResult = await requestAmTrustQuote(
            getFormData(),
            selectedCoverages
          );
          setQuoteResult(quoteResult);
          await handleLoadQuestions(quoteResult);
          setViewMode(VIEW_MODE_QUESTIONS);
          notify(
            "Success",
            "AmTrust preliminary quote created! Please answer the questions in the upcoming screen.",
            "success",
            "bottomLeft"
          );
          break;
        case INSURANCE_CARRIER_KEY_GA:
          if (!profileVal.tax_id) {
            notify(
              "Action Aborted",
              "Franchisee doesn't have FEIN/Tax number. The FEIN/Tax number is required for Great American quotes."
            );
            break;
          }
          const appId = await getAppId(
            nacisCode?.code || franchisorNaicsCode?.code,
            profileVal,
            effectiveDate[COVERAGE_KEY_WORKERS_COMP]?.value,
            expirationDate[COVERAGE_KEY_WORKERS_COMP]?.value,
            employeeLiability
          );
          history.push(`/admin/eligibility/${id}/${appId}`);
          break;
        default:
          break;
      }
      setCurrentStep(currentStep + 1);
      setLoading(false);
    } catch (error) {
      const errMsg =
        error.response?.data?.message ||
        error.message ||
        "Failed to quote - please contact support";
      notify("Error", JSON.stringify(errMsg), "error");
    } finally {
      setLoading(false);
    }
  }

  async function handleAcceptQuote() {
    try {
      setLoading(true);
      switch (selectedCarrier) {
        case INSURANCE_CARRIER_KEY_HARTFORD:
          await acceptHartfordQuote(id, franchiseeData, quoteResult);
          break;
        case INSURANCE_CARRIER_KEY_AMTRUST:
          break;
        case INSURANCE_CARRIER_KEY_GA:
          break;
        default:
          break;
      }
      notify("Success", "Hartford quote accepted!", "success", "bottomLeft");
      setLoading(false);
      history.push(`/admin/franchisees/${id}`);
    } catch (error) {
      console.log("error", error);
      notify("Error", "Failed to accept quote", "error");
    } finally {
      setLoading(false);
    }
  }

  async function saveAmTrustQuestions() {
    const token = await getAccessTokenSilently();
    await put(
      `contact/${franchiseeData._id}`,
      {
        amTrustQuoteQuestions: quoteQuestions,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  async function answerAmTrustQuestions(quoteId) {
    if (
      quoteQuestions.filter(
        (q) => q["Answer"] === undefined || q["Answer"] === null
      ).length > 0
    ) {
      notify("Error", "All questions must be answered before proceeding.");
      return;
    }
    const body = {
      quoteId,
      questionsAnswers: quoteQuestions.map((q) => ({
        questionId: q["Id"],
        answerValue: q["Answer"],
      })),
    };
    const token = await getAccessTokenSilently();
    const result = await post(`amtrust/questions/answer`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data?.data["Eligibility"];
  }

  async function fetchAmTrustQuestions(quoteId) {
    const token = await getAccessTokenSilently();
    const result = await post(
      `/amtrust/questions`,
      { quoteId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return result.data?.data["Data"] || [];
  }

  async function handleSaveQuestions() {
    try {
      setLoading(true);
      switch (selectedCarrier) {
        case INSURANCE_CARRIER_KEY_HARTFORD:
          break;
        case INSURANCE_CARRIER_KEY_AMTRUST:
          const eligibilityData = await answerAmTrustQuestions(
            quoteResult["AccountInformation"]["QuoteId"]
          );
          flushSync(() => {
            setQuoteResult((prev) => ({
              ...prev,
              Eligibility: eligibilityData ?? prev.Eligibility,
            }));
          });
          await saveAmTrustQuestions();
          break;
        case INSURANCE_CARRIER_KEY_GA:
          break;
        default:
          break;
      }
      notify("Success", "Questions saved!", "success", "bottomLeft");
      setLoading(false);
      setCurrentStep(currentStep + 1);
      setViewMode(VIEW_MODE_PREVIEW);
    } catch (error) {
      console.log("error", error);
      notify("Error", "Failed to save questions", "error");
    } finally {
      setLoading(false);
    }
  }

  async function handleLoadQuestions(quoteResult) {
    try {
      setLoading(true);
      switch (selectedCarrier) {
        case INSURANCE_CARRIER_KEY_HARTFORD:
          break;
        case INSURANCE_CARRIER_KEY_AMTRUST:
          let answerMap = {};
          for (const dbQuestion of franchiseeData.amTrustQuoteQuestions || []) {
            answerMap[dbQuestion["Id"]] = dbQuestion["Answer"];
          }

          let questionsFromAmTrust = await fetchAmTrustQuestions(
            quoteResult["AccountInformation"]["QuoteId"]
          );
          questionsFromAmTrust = questionsFromAmTrust.map((q) => ({
            ...q,
            Answer: answerMap[q["Id"]],
          }));
          setQuoteQuestions(questionsFromAmTrust);
          break;
        case INSURANCE_CARRIER_KEY_GA:
          break;
        default:
          break;
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      notify("Error", "Failed to load questions", "error");
    } finally {
      setLoading(false);
    }
  }

  function effectiveExpirationFormProps(coverageKey) {
    return {
      effectiveDate: effectiveDate[coverageKey]?.value,
      expirationDate: expirationDate[coverageKey]?.value,
      onEffectiveDateChange: (date, error) => {
        setEffectiveDate((prev) => ({
          ...prev,
          [coverageKey]: { value: date, error },
        }));
      },
      onExpirationDateChange: (date, error) => {
        setExpirationDate((prev) => ({
          ...prev,
          [coverageKey]: { value: date, error },
        }));
      },
    };
  }

  function workerCompCoverageForm() {
    const validLimitCombinations = {
      // Pegged to injury.
      [toUSD(100000)]: { injury: 100000, policy: 500000, employee: 100000 },
      [toUSD(1000000)]: { injury: 1000000, policy: 1000000, employee: 1000000 },
      [toUSD(500000)]: { injury: 500000, policy: 500000, employee: 500000 },
    };

    return (
      <Card title={COVERAGES.wc.title}>
        <FormEffectiveExpireDates
          {...effectiveExpirationFormProps(COVERAGE_KEY_WORKERS_COMP)}
        />

        <div className="form-group-four-container" style={{ marginBottom: 20 }}>
          <Input
            title="Experience Modifier (0.4 - 2.0)"
            type="number"
            min="0.4"
            max="2.0"
            step="0.1"
            value={underwritingInfo.modifier}
            onChange={(evt) =>
              setUnderwritingInfo((prev) => ({
                ...prev,
                modifier: evt.target.value,
              }))
            }
          />
          <InputSelect
            title="Bodily Injury by Accident/ Each accident"
            size="large"
            value={toUSD(employeeLiability.injury)}
            onChange={(selected) =>
              setEmployeeLiability(validLimitCombinations[selected])
            }
            options={_.keys(validLimitCombinations).map((key) => ({
              label: key,
              value: key,
            }))}
          />
          <Input
            disabled
            title="Policy Limit (autofilled)"
            type="text"
            value={toUSD(employeeLiability.policy)}
          />
          <Input
            disabled
            title="Employee limit (autofilled)"
            type="text"
            value={toUSD(employeeLiability.employee)}
          />
        </div>
        <LocationBlock
          franchiseeData={franchiseeData}
          coverageType={COVERAGE_KEY_WORKERS_COMP}
          data={locationVal}
          codeData={franchisorData.naics_classes || []}
          onChange={setLocationVal}
        />
        <OfficeBlock data={officeVal} onChange={setOfficeVal} />
      </Card>
    );
  }

  function propertyCoverageForm() {
    return (
      <Card title={COVERAGES.prop.title}>
        <FormEffectiveExpireDates
          {...effectiveExpirationFormProps(COVERAGE_KEY_PROPERTY)}
        />
        <LocationBlock
          franchiseeData={franchiseeData}
          coverageType={COVERAGE_KEY_PROPERTY}
          data={locationVal}
          onChange={setLocationVal}
        />
      </Card>
    );
  }

  function liabilityCoverageForm() {
    return (
      <Card title={COVERAGES.li.title}>
        <FormEffectiveExpireDates
          {...effectiveExpirationFormProps(COVERAGE_KEY_LIABILITY)}
        />
        <LocationBlock
          codeData={franchisorData.naics_classes || []}
          franchiseeData={franchiseeData}
          coverageType={COVERAGE_KEY_LIABILITY}
          data={locationVal}
          formCode={formCode}
          onChange={setLocationVal}
        />
      </Card>
    );
  }

  function renderCoverageForms() {
    var forms = [];
    if (selectedCoverages[COVERAGE_KEY_WORKERS_COMP]) {
      forms.push(workerCompCoverageForm());
    }
    if (selectedCoverages[COVERAGE_KEY_PROPERTY]) {
      forms.push(propertyCoverageForm());
    }
    if (selectedCoverages[COVERAGE_KEY_LIABILITY]) {
      forms.push(liabilityCoverageForm());
    }
    return forms;
  }

  function renderQuoteResultPreview() {
    switch (selectedCarrier) {
      case INSURANCE_CARRIER_KEY_HARTFORD:
        const hfWCQuoteProposalData = constructHFWCQuoteProposal(
          quoteResult.wc || {},
          QUOTE_PROPOSAL_SOURCE_API,
          getFormData()
        );
        // todo: for now, since we don't have quote proposal design for liability and prop
        // we show the new wc quote proposal only if wc is the only product selected.
        if (
          _.keys(selectedCoverages).length <= 1 &&
          selectedCoverages[COVERAGE_KEY_WORKERS_COMP]
        ) {
          return (
            <AdminInsuranceQuoteProposal
              data={{
                [selectedCarrier]: {
                  [COVERAGE_KEY_WORKERS_COMP]: hfWCQuoteProposalData,
                },
              }}
              initialSelectedCarrier={selectedCarrier}
              initialSelectedCoverages={selectedCoverages}
              initialSelectedStatus={hfWCQuoteProposalData.status}
              initialSelectedSource={hfWCQuoteProposalData.source}
              readOnly={true}
            />
          );
        }
        return <HartfordPreview {...quoteResult} />;
      case INSURANCE_CARRIER_KEY_AMTRUST:
        const amTrustWCQuoteProposalData = constructAmTrustWCQuoteProposal(
          quoteResult,
          QUOTE_PROPOSAL_SOURCE_API,
          getFormData()
        );
        return (
          <AdminInsuranceQuoteProposal
            data={{
              [selectedCarrier]: {
                [COVERAGE_KEY_WORKERS_COMP]: amTrustWCQuoteProposalData,
              },
            }}
            initialSelectedCarrier={selectedCarrier}
            initialSelectedCoverages={selectedCoverages}
            initialSelectedStatus={amTrustWCQuoteProposalData.status}
            initialSelectedSource={amTrustWCQuoteProposalData.source}
            readOnly={true}
          />
        );
      default:
        return <></>;
    }
  }
  function renderQuoteQuestions() {
    switch (selectedCarrier) {
      case INSURANCE_CARRIER_KEY_AMTRUST:
        return (
          <AmTrustQuoteQuestions
            onChange={setQuoteQuestions}
            quoteQuestions={quoteQuestions}
          />
        );
      default:
        return <></>;
    }
  }

  function renderNaicsInputForm() {
    if (selectedCarrier != INSURANCE_CARRIER_KEY_GA) {
      return <></>;
    }
    if (franchisorNaicsCode) {
      return (
        <Card className="form-holder-card" title="Insurance recommendation">
          <div>
            <label>NAICS Code</label>
            <div className="naics-code-container">
              <Input value={franchisorNaicsCode.code} disabled />
              <Input value={franchisorNaicsCode.description} disabled />
            </div>
          </div>
        </Card>
      );
    }
    return (
      <Card className="form-holder-card" title="Insurance recommendation">
        <Alert
          type="warning"
          message="There is no associated NAICS Code. You need to select one."
        />
        <div className="">
          <label>NAICS Code</label>
          <ClassCodeInput
            typeVal="NAICS"
            codeVal={nacisCode.code}
            descVal={nacisCode.description}
            setCodeVal={(val) => handleChangeNacis("code", val)}
            setDescVal={(val) => handleChangeNacis("description", val)}
            setSelect={(val) => handleSelectNacis("main", val)}
          />
        </div>
      </Card>
    );
  }

  function renderAccountForm() {
    return (
      <Card className="form-holder-card" title="Account">
        <FormProfile data={profileVal} onChange={setProfileVal} />
      </Card>
    );
  }

  return (
    <div className="admin_insurance_quote">
      <QuoteProgressBar
        step={currentStep}
        total={NUM_STEPS_TO_QUOTE[selectedCarrier]}
      ></QuoteProgressBar>
      <QuoteHeader
        franchiseeData={franchiseeData}
        requirement={franchisorData}
        user={user}
        expirationDate={expirationDate}
      ></QuoteHeader>
      <div className="admin_insurance_quote-container">
        <QuoteStepper
          step={currentStep}
          total={NUM_STEPS_TO_QUOTE[selectedCarrier]}
        ></QuoteStepper>
        <div className="right-content">
          {viewMode === VIEW_MODE_FORM && (
            <>
              <div className="admin_insurance_quote-container-wrapper">
                {!greatAmericanUsable ? (
                  <Alert
                    type="warning"
                    message="The Great American API cannot be used for this franchisee, because we couldn't determine the appropriate NAICS code. If you think this is a mistake, please contact us."
                  />
                ) : (
                  <></>
                )}
                <CoverageCarrierSelection
                  disabledCarriers={{
                    [INSURANCE_CARRIER_KEY_GA]: !greatAmericanUsable,
                  }}
                  selectedCarrier={selectedCarrier}
                  selectedCoverages={selectedCoverages}
                  setSelectedCarrier={setSelectedCarrier}
                  setSelectedCoverages={setSelectedCoverages}
                />
                {renderNaicsInputForm()}
                {renderAccountForm()}
                {renderCoverageForms()}
              </div>
              <QuoteAction
                handleClose={handleClose}
                handleNext={handleNext}
                visibleBack={false}
                visibleSave={false}
              ></QuoteAction>
            </>
          )}
          {viewMode === VIEW_MODE_PREVIEW && (
            <>
              {renderQuoteResultPreview()}
              <QuoteAction
                handleAccept={handleAcceptQuote}
                handleClose={handleClose}
                handleSave={handleSaveQuote}
                visibleBack={false}
                visibleNext={false}
              ></QuoteAction>
            </>
          )}
          {viewMode === VIEW_MODE_QUESTIONS && (
            <>
              {renderQuoteQuestions()}
              <QuoteAction
                handleClose={handleClose}
                handleSave={handleSaveQuestions}
                visibleBack={false}
                visibleNext={false}
              ></QuoteAction>
            </>
          )}
        </div>
      </div>

      <Loading loading={loading} />
    </div>
  );
}

import _ from "lodash";
import "./style.scss";
import { useFieldArray, useWatch } from "react-hook-form";
import InputText from "@components/input/text";
import { DeleteTwoTone, UsergroupAddOutlined } from "@ant-design/icons";
import { Button, DatePicker } from "antd";
import { z } from "zod";
import { requiredZodErr } from "@utils/common";
import InputDatePicker from "@components/input/date";

export default function DriverForm({
  disableValidation,
  rhfKey,
  form: {
    register,
    control,
    formState: { errors },
    trigger,
  },
}) {
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: rhfKey, // unique name for your Field Array
  });

  function renderDriverInfo(field, driverIndex) {
    return (
      <div key={field.id} className="driver-info-container">
        <div className="form-group-five-container">
          <InputText
            title="First name"
            rhfKey={`${rhfKey}.${driverIndex}.firstName`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.[driverIndex]?.firstName?.message}
          />
          <InputText
            title="Last name"
            rhfKey={`${rhfKey}.${driverIndex}.lastName`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.[driverIndex]?.lastName?.message}
          />
          <InputText
            title="Driver's license"
            rhfKey={`${rhfKey}.${driverIndex}.driversLicenseNumber`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.[driverIndex]?.driversLicenseNumber?.message}
          />
          <InputText
            title="State of license"
            rhfKey={`${rhfKey}.${driverIndex}.stateOfLicense`}
            register={register}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.[driverIndex]?.stateOfLicense?.message}
          />
          <InputDatePicker
            title="Date of birth"
            control={control}
            rhfKey={`${rhfKey}.${driverIndex}.dateOfBirth`}
            disableValidation={disableValidation}
            error={errors[rhfKey]?.[driverIndex]?.dateOfBirth?.message}
          />
        </div>
        <DeleteTwoTone
          className="delete-button"
          twoToneColor="#f5222d"
          onClick={() => {
            remove(driverIndex);
          }}
        />
      </div>
    );
  }

  return (
    <div>
      {fields.map((field, index) => renderDriverInfo(field, index))}
      <div className="add-button-container">
        <Button
          type="primary"
          onClick={() => {
            const newDriver = {
              firstName: "",
              lastName: "",
              driversLicenseNumber: "",
              stateOfLicense: "",
              dateOfBirth: "",
            };
            append(newDriver);
            trigger(
              Object.keys(newDriver).map(
                (k) => `${rhfKey}.${fields.length}.${k}`
              )
            );
          }}
        >
          <UsergroupAddOutlined />
          Add Driver
        </Button>
      </div>
    </div>
  );
}

export const defaultSchema = z.array(
  z.object({
    firstName: z
      .string(requiredZodErr("First name"))
      .nonempty(requiredZodErr("First name")),
    lastName: z
      .string(requiredZodErr("Last name"))
      .nonempty(requiredZodErr("Last name")),
    driversLicenseNumber: z
      .string(requiredZodErr("Drivers license number"))
      .nonempty(requiredZodErr("Drivers license number")),
    stateOfLicense: z
      .string(requiredZodErr("State of license"))
      .nonempty(requiredZodErr("State of license")),
    dateOfBirth: z
      .string(requiredZodErr("Date of birth"))
      .nonempty(requiredZodErr("Date of birth")),
  })
);

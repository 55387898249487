import React from "react";
import InputWrapper from "../wrapper";
import ReactInputMask from "react-input-mask";
import "../style.scss";

const InputMask = React.forwardRef(
  ({ mask, disableValidation, register = () => {}, rhfKey, ...props }, ref) => {
    return (
      <InputWrapper {...props}>
        <ReactInputMask
          ref={ref}
          className="custom-input ant-input ant-input-lg custom-antd-input"
          mask={mask}
          {...props}
          {...register(
            rhfKey,
            disableValidation ? { validate: () => true } : {}
          )}
        ></ReactInputMask>
      </InputWrapper>
    );
  }
);

export default InputMask;

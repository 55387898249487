import React, { useState } from "react";
import InputWrapper from "../wrapper";
import "../style.scss";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";

const InputShowHide = React.forwardRef(({ renderInput, ...props }, ref) => {
  const [obscured, setObscured] = useState(true);

  return (
    <InputWrapper {...props}>
      <div className="show-hide-input-container">
        {renderInput(obscured)}
        <div
          className="show-hide-box"
          onClick={() => {
            setObscured(!obscured);
          }}
        >
          {!obscured ? <EyeFilled /> : <EyeInvisibleFilled />}
        </div>
      </div>
    </InputWrapper>
  );
});

export default InputShowHide;

import React from "react";
import { Button, Switch } from "antd";
import { Input } from "../../../../../components";
import { CloseOutlined } from "@ant-design/icons";
import "./style.scss";

export default function ContactItem(props) {
  const { data, onChange, onClose } = props;

  function handleClose() {
    onClose();
  }
  return (
    <div className="contact_item">
      <div className="form-group-three-container">
        <div className="">
          <Input
            title="First Name*"
            type="text"
            placeholder="First Name*"
            value={data.firstName}
            onChange={(evt) => onChange("firstName", evt.target.value)}
          ></Input>
          <Input
            title="Last Name*"
            type="text"
            placeholder="Last Name*"
            value={data.lastName}
            onChange={(evt) => onChange("lastName", evt.target.value)}
          ></Input>
          <Input
            title="Email*"
            type="text"
            placeholder="Email*"
            value={data.email}
            onChange={(evt) => onChange("email", evt.target.value)}
          ></Input>
          <Input
            title="Telephone*"
            type="text"
            placeholder="Telephone*"
            value={data.telephone}
            onChange={(evt) => onChange("telephone", evt.target.value)}
          ></Input>
        </div>

        <div className="contact_item-wrapper">
          <label className="contact_item-wrapper-label">Reports</label>
          <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">
              Monthly Executive Summary - Insurance Report
            </span>
            <Switch
              checked={data.isReport}
              onChange={(check) => onChange("isReport", check)}
            />
          </div>
          <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">
              Recently Insured By Rikor Report Report
            </span>
            <Switch
              checked={data.isBindPolicy}
              onChange={(check) => onChange("isBindPolicy", check)}
            />
          </div>
          {/* <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">
              Canceled Policies Notification
            </span>
            <Switch
              checked={data.isCancelPolicy}
              onChange={(check) => onChange("isCancelPolicy", check)}
            />
          </div> */}
          <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">
              Franchisee Lapse in Coverage Report
            </span>
            <Switch
              checked={data.isLapseCoverage}
              onChange={(check) => onChange("isLapseCoverage", check)}
            />
          </div>
          <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">
              Inactive Franchisee Report
            </span>
            <Switch
              checked={data.isInactiveZee}
              onChange={(check) => onChange("isInactiveZee", check)}
            />
          </div>
          <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">
              Non-Compliant Franchisee Report
            </span>
            <Switch
              checked={data.isNetPromoter}
              onChange={(check) => onChange("isNetPromoter", check)}
            />
          </div>
          <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">
              POC Confirmation Request
            </span>
            <Switch
              checked={data.pocConfirm}
              onChange={(check) => onChange("pocConfirm", check)}
            />
          </div>
          <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">
              New Lead Report
            </span>
            <Switch
              checked={data.newLeadReport}
              onChange={(check) => onChange("newLeadReport", check)}
            />
          </div>
          <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">
              COI Upload Report
            </span>
            <Switch
              checked={data.coiUploadReport}
              onChange={(check) => onChange("coiUploadReport", check)}
            />
          </div>
          <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">
              Consultant Introduction Email
            </span>
            <Switch
              checked={data.consultIntroEmail}
              onChange={(check) => onChange("consultIntroEmail", check)}
            />
          </div>
        </div>
        <div className="contact_item-wrapper">
          <label className="contact_item-wrapper-label">Contact Activity</label>
          <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">Primary</span>
            <Switch
              checked={data.isPrimary}
              onChange={(check) => onChange("isPrimary", check)}
            />
          </div>
          <div className="contact_item-wrapper-item">
            <span className="contact_item-wrapper-item-label">Active</span>
            <Switch
              checked={data.isActive}
              onChange={(check) => onChange("isActive", check)}
            />
          </div>
        </div>
      </div>
      <div className="contact_item-action">
        <CloseOutlined
          className="contact_item-action-icon"
          onClick={handleClose}
        />
      </div>
    </div>
  );
}

import { useCallback, useState } from "react";
import "./style.scss";
import { Alert, Input, Loading } from "@components";
import { Button, Checkbox, DatePicker, Form } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { post } from "@utils/axios";
import { usePlacesWidget } from "react-google-autocomplete";
import { getHostTag } from "@utils/host";
import { TYPE_INPUT_TAX_ID } from "@assets/const/ui";
import { extractLocationData } from "@utils/google-map";
import _, { isEmpty } from "lodash";
import { DO_NOT_CONTACT_OPTION_IS_LEAD } from "@assets/const/status";
import { APP_DATE_FORMAT } from "@assets/const/form";

export default function AddFranchiseeForm(props) {
  const [zeeForm] = Form.useForm();
  const [formVal, setFormVal] = useState({
    address: "",
    city: "",
    state: "",
    zip: ""
  });
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [emailExists, setEmailExists] = useState(false);
  const [entityExists, setEntityExists] = useState(false);

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    options: {
      types: ["address"]
    },
    onPlaceSelected: (place) => {
      const { zip, city, state, address } = extractLocationData(
        place.address_components
      );

      setFormVal((prev) => ({
        ...prev,
        address: [address, city, state, zip].filter(Boolean).join(", "),
        city,
        state,
        zip
      }));
    }
  });

  const searchExistingFranchisee = useCallback(
    _.debounce(async (data) => {
      if (data) {
        const token = await getAccessTokenSilently();
        const foundFranchisee = await post(
          `admin/franchisee`,
          {
            host: props.host || getHostTag(),
            franchisorId: props.requirementId,
            query: {
              $or: [
                {
                  "insuranceData.email": data.toLowerCase()
                },
                {
                  email: data.toLowerCase()
                }
              ]
            }
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const found = foundFranchisee.data?.data;
        setEmailExists(found && found.length > 0);
      }
    }, 1000),
    []
  );

  const searchExistingFranchiseeEntity = useCallback(
    _.debounce(async (data) => {
      if (data) {
        const token = await getAccessTokenSilently();
        const foundFranchisee = await post(
          `admin/franchisee`,
          {
            host: props.host || getHostTag(),
            franchisorId: props.requirementId,
            query: {
              $or: [
                {
                  "insuranceData.entity_name": data
                },
                {
                  entity_name: data
                }
              ]
            }
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const found = foundFranchisee.data?.data;
        setEntityExists(found && found.length > 0);
      }
    }, 1000),
    []
  );

  function handleChange(key, value) {
    setFormVal({
      ...formVal,
      [key]: value
    });
  }

  function handleCancel() {
    const { onCancel } = props;
    onCancel();
    setFormVal({
      address: "",
      city: "",
      state: "",
      zip: ""
    });
    zeeForm.resetFields();
  }

  async function handleSubmit(values) {
    const { onOk } = props;

    const param = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email.toLowerCase(),
      telephone: values.telephone,
      entity_name: values.entity_name,
      dba: values.dba,
      opening_date: values?.opening_date?.format("YYYY-MM-DD"),
      tax_id: values.tax_id,
      notes: values.notes,
      address: formVal.address,
      city: formVal.city,
      state: formVal.state,
      zip: formVal.zip,
      centerName: values.centerName,
      host: props.host || getHostTag()
    };

    if (values.is_lead) {
      param["doNotContact"] = DO_NOT_CONTACT_OPTION_IS_LEAD;
    }

    try {
      const token = await getAccessTokenSilently();
      setLoading(true);
      const result = await post(
        `contact`,
        {
          ...param,
          requirementId: props.requirementId
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      if (result.data.success) {
      }

      setLoading(false);
      onOk();
      setFormVal({
        address: "",
        city: "",
        state: "",
        zip: ""
      });
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <div className="add-franchisee-wrapper">
      <Form form={zeeForm} onFinish={handleSubmit}>
        <div className="email-container">
          <Form.Item
            name="email"
            rules={[
              { type: "email", message: "Please input valid email." },
              { required: true, message: "Please input your email." }
            ]}
          >
            <Input
              title="Email"
              type="text"
              placeholder="Email"
              onChange={(evt) => searchExistingFranchisee(evt.target.value)}
            />
          </Form.Item>
          {emailExists && (
            <div className="email-container-error">
              <Alert
                status="error"
                message={`Contact already exists! Do not make another record.`}
                okLabel="View Zee Records"
              />
            </div>
          )}
        </div>

        <div className="email-container">
          <Form.Item
            name="entity_name"
            rules={[
              { required: true, message: "Please input your entity name." }
            ]}
          >
            <Input
              title="Entity Name"
              type="text"
              placeholder="Entity Name"
              onChange={(evt) =>
                searchExistingFranchiseeEntity(evt.target.value)
              }
            />
          </Form.Item>
          {entityExists && (
            <div className="email-container-error">
              <Alert
                status="error"
                message={`Entity already exists! Do not make another record.`}
                okLabel="View Zee Records"
              />
            </div>
          )}
        </div>

        <div className="add-franchisee-container">
          <div className="panel-left">
            <div className="form-group-two-container">
              <Form.Item
                name="first_name"
                rules={[
                  { required: true, message: "Please input your first name." }
                ]}
              >
                <Input
                  title="First Name"
                  type="text"
                  placeholder="First Name"
                />
              </Form.Item>
              <Form.Item
                name="last_name"
                rules={[
                  { required: true, message: "Please input your last name." }
                ]}
              >
                <Input title="Last Name" type="text" placeholder="Last Name" />
              </Form.Item>
            </div>
            <div className="form-group-two-container">
              <Form.Item name="telephone">
                <Input
                  title="Telephone"
                  type="text"
                  inputType="TYPE_INPUT_PHONE"
                  placeholder="Telephone"
                />
              </Form.Item>
              <div>
                <p className="input-title">Opening Date</p>

                <Form.Item name="opening_date">
                  <DatePicker
                    format={APP_DATE_FORMAT}
                    size="large"
                    style={{ width: "100%" }}
                  ></DatePicker>
                </Form.Item>
              </div>
            </div>

            <div className="line-item-container">
              <Form.Item name="notes">
                <Input title="Notes" type="text" placeholder="Notes" />
              </Form.Item>
            </div>
            <div className="form-group-two-container">
              <Form.Item name="centerName">
                <Input
                  title="Center Name"
                  type="text"
                  placeholder="Center Name"
                />
              </Form.Item>
              <div className="custom-input-container">
                <p className="input-title">New Lead</p>
                <Form.Item name="is_lead" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="panel-right">
            <div className="form-group-two-container">
              <Form.Item name="dba">
                <Input title="DBA" type="text" placeholder="DBA" />
              </Form.Item>

              <Form.Item name="tax_id">
                <Input
                  title="Tax ID"
                  inputType={TYPE_INPUT_TAX_ID}
                  placeholder="Tax ID"
                />
              </Form.Item>
            </div>
            <div className="line-item-container address-item">
              <Input
                title="Address"
                type="text"
                placeholder="Address"
                value={formVal.address}
                ref={ref}
                inputType="TYPE_INPUT_ADDRESS"
                onChange={(evt) => handleChange("address", evt.target.value)}
              />
            </div>
            <div className="form-group-three-container line-item-container">
              <Input
                title="City"
                type="text"
                placeholder="City"
                value={formVal.city}
                onChange={(evt) => handleChange("city", evt.target.value)}
              />
              <Input
                title="State"
                type="text"
                placeholder="State"
                value={formVal.state}
                onChange={(evt) => handleChange("state", evt.target.value)}
              />
              <Input
                title="Zip"
                type="text"
                placeholder="Zip"
                value={formVal.zip}
                onChange={(evt) => handleChange("zip", evt.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="action-container">
          <Button type="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={emailExists || entityExists}
          >
            Add
          </Button>
        </div>
      </Form>
      <Loading loading={loading} />
    </div>
  );
}

import React from "react";
import { Controller } from "react-hook-form";
import InputWrapper from "../wrapper";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { APP_DATE_FORMAT } from "@assets/const/form";

const InputDatePicker = React.forwardRef(
  ({ rhfKey, control, error, disabled, disableValidation, ...props }, ref) => {
    const input = ({ field = {} }) => (
      <InputWrapper {...props} error={error}>
        <DatePicker
          format={APP_DATE_FORMAT}
          className="custom-input"
          size="large"
          style={{ width: "100%", ...props.style }}
          {...props}
          {...field}
          value={field.value ? dayjs(field.value) : undefined}
          onChange={(_, dateString) => {
            field.onChange(dateString);
          }}
        />
      </InputWrapper>
    );

    if (control) {
      return (
        <Controller
          name={rhfKey}
          control={control}
          rules={disableValidation ? { validate: () => true } : undefined}
          render={({ field }) => input({ field })}
        />
      );
    }
    return input({});
  }
);

export default InputDatePicker;
